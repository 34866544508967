import { Box, useTheme, Button, ButtonGroup } from "@mui/material";
import { tokens } from "./theme";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { useSelector,useDispatch } from "react-redux";
import { FormControlLabel, Checkbox } from "@mui/material";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import { useEffect, useState, useMemo, React } from "react";
import { debounce } from "lodash";
import EditRoute from "./EditRoute";
import axios from "axios";
import AddNewRoute from "./addNewRoute";
import "./styles.css";
import { makeStyles } from "@mui/styles";
import {active} from "./activeslice";

const useStyles = makeStyles((theme) => ({
  main: {
    color: "seagreen",

    "&.Mui-checked": {
      color: "seagreen",
    },
  },
}));

const Routemgmt = () => {
  const theme = useTheme();
  const dispatch=useDispatch();
  const classes = useStyles();
  const colors = tokens(theme.palette.mode);
  const getRowId = (row) => row.routePriceId;
  const [currentPage, setCurrentPage] = useState(0);
  const [pagnation, setPagination] = useState(null);
  const [gridKey, setGridKey] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const searchval = useSelector((state) => state.search.searchValue);
  const memoizedSearchVal = useMemo(() => searchval, [searchval]);
  const [fetchedData, setFetchData] = useState([]);
  const [dataFetched, setDataFetched] = useState(false);
  const [addRoute, setAddRoute] = useState(false);
  const [selectCheck, setSelectCheck] = useState({
    active: true,
    inactive: false,
  });
  const [editRouteVisibility, setEditRouteVisibility] = useState({
    visibility: false,
    data: null,
  });
  const reduxdata = useSelector((state) => state.auth.data.authorization);
  const memoizedReduxData = useMemo(() => reduxdata, [reduxdata]);
  const columns = [
    /*{ field: "placeId", headerName: "ID", flex: 0.5, hide:true },
    { field: "registrarId", headerName: "Place name" },*/
    {
      field: "fromPlaceName",
      headerName: "Source",
      flex: 1,
      cellClassName: "name-column--cell",
    },
    {
      field: "toPlaceName",
      headerName: "Destination",
      flex: 1,
      type: "number",
      headerAlign: "left",
      align: "left",
    },
    {
      field: "btCommission",
      headerName: "BT Price",
      flex: 1,
      valueGetter: (params) => {
        const btPrice = params.row.btCommission;
        const btType = params.row.btCommissionType;
        return `${btPrice} / ${btType}`;
      },
    },
    {
      field: "ezCommisiion",
      headerName: "EZ Price",
      flex: 1,
      valueGetter: (params) => {
        const ezPrice = params.row.ezCommission;
        const ezType = params.row.ezCommissionType;
        return `${ezPrice} / ${ezType}`;
      },
    },
    {
      field: "biCommisiion",
      headerName: "BI Price",
      flex: 1,
      valueGetter: (params) => {
        const biPrice = params.row.biCommission;
        const biType = params.row.biCommissionType;
        return `${biPrice} / ${biType}`;
      },
    },
    {
      field: "routePriceId",
      headerName: "Edit",
      flex: 0.5,
      renderCell: (params) => {
        const handleEdit = (row) => {
          if (row !== null) {
            setEditRouteVisibility((prevEditRouteVisibility) => ({
              ...prevEditRouteVisibility,
              visibility: true,
              data: row,
            }));
          }
        };
        return (
          <a
            key={params.row.routePriceId}
            onClick={() => handleEdit(params.row)}
          >
            {" "}
            <EditOutlinedIcon style={{ verticalAlign: "middle" }} />
            Edit
          </a>
        );
      },
    },
  ];

  const handlePageChange = (params) => {
    setCurrentPage(params);
    debouncedFetchData(memoizedSearchVal, selectCheck, params);
  };
  const handleChange = (e) => {
    if (e.target.name === "active") {
      if (selectCheck.active === true) {
        setSelectCheck({
          ...selectCheck,
          active: false,
        });
      } else {
        setSelectCheck({
          inactive: false,
          active: true,
        });
      }
    } else {
      if (selectCheck.inactive === true) {
        setSelectCheck({
          ...selectCheck,
          inactive: false,
        });
      } else {
        setSelectCheck({
          active: false,
          inactive: true,
        });
      }
    }
  };
  const debouncedFetchData = useMemo(
    () =>
      debounce((searchVal, selectCheck, page) => {
        const datatosend = {
          searchString: searchVal,
          page: page,
          size: 6,
          routeStatus: selectCheck.active === true ? "active" : "inactive",
        };
        console.log(datatosend);
        const axiosConfig = {
          headers: {
            Authorization: memoizedReduxData,
          },
        };

        axios
          .post(
            "https://api.freezebooking.in/v1/admin/getAllRoutePricing",
            datatosend,
            axiosConfig
          )
          .then((response) => {
            const data = response.data;
            if (data.meta.status === true && data.meta.code === 200) {
              setFetchData(data.data.routePricingList);
              setPagination(data.data.totalItems);
              setDataFetched(true);
            } else {
              console.log(data.meta.message);
            }
          })
          .catch((error) => {
            console.error("API error:", error);
          })
          .finally(() => {
            setIsLoading(false);
          });
      }, 300),
    []
  );

  useEffect(() => {
    setIsLoading(true);
    setDataFetched(false);
    debouncedFetchData(memoizedSearchVal, selectCheck, currentPage);
  }, [memoizedSearchVal, selectCheck, debouncedFetchData, currentPage]);

  useEffect(()=>{
    dispatch(active("Application Settings"));
    },[]);

  const handleChildButtonClick = () => {
    setAddRoute(false);
    setEditRouteVisibility({ visibility: false, data: null });
    debouncedFetchData(memoizedSearchVal, selectCheck, currentPage);
  };
  const handleAddRoute = () => {
    setAddRoute(true);
  };
  return (
    <>
      {addRoute ? (
        <AddNewRoute onButtonClick={handleChildButtonClick} />
      ) : editRouteVisibility.visibility ? (
        <EditRoute
          onButtonClick={handleChildButtonClick}
          data={editRouteVisibility.data}
        />
      ) : (
        <Box m="20px">
          <ButtonGroup
            style={{
              width: "100%",
              color: "whitesmoke",
              display:
                "flex" /*display: showNewCoupon ? 'none' : '' || editUserVisibility.visibility?'none':''*/,
            }}
          >
            <Box
              border={1}
              borderColor="white"
              borderRadius={5}
              style={{ flex: 1, minWidth: "250px" }}
            >
              <Button
                name="route"
                style={{
                  width: "100%",
                  minWidth: "250px",
                  backgroundColor: "whitesmoke",
                  color: "#002244",
                  borderColor: "black",
                }}
              >
                Route Price Management
              </Button>
            </Box>
            <Box
              border={1}
              borderColor="white"
              borderRadius={5}
              style={{ flex: 2 }}
            >
              <Button
                name=""
                style={{
                  width: "100%",
                  backgroundColor: "#002244",
                  color: "#002244",
                  borderColor: "black",
                }}
              >
                hello
              </Button>
            </Box>
          </ButtonGroup>

          <Box
            display="flex"
            mt="20px"
            gridTemplateColumns="repeat(4, minmax(0, 1fr))"
          >
            <Box
              display="flex"
              style={{
                backgroundColor: "white",
                marginLeft: "7vw",
                border: "2px solid black",
              }}
            >
              <Box sx={{ flex: 0.3, ml: "7px" }}>
                <FormControlLabel
                  name="active"
                  control={
                    <Checkbox
                      className={classes.main}
                      checked={selectCheck.active}
                      sx={{
                        color: selectCheck.active ? "seagreen" : "black",
                        "&.Mui-checked": {
                          color: "seagreen",
                        },
                        "&:hover": {
                          backgroundColor: "transparent", // optional, removes hover effect
                        },
                      }}
                      onChange={handleChange}
                    />
                  }
                  label="Active"
                />
              </Box>
              <Box sx={{ flex: 0.5 }}>
                <FormControlLabel
                  name="inactive"
                  control={
                    <Checkbox
                      className={classes.main}
                      checked={selectCheck.inactive}
                      sx={{
                        color: selectCheck.inactive ? "seagreen" : "black",
                        "&.Mui-checked": {
                          color: "seagreen",
                        },
                        "&:hover": {
                          backgroundColor: "transparent", // optional, removes hover effect
                        },
                      }}
                      onChange={handleChange}
                    />
                  }
                  label="Inactive"
                />
              </Box>
            </Box>
            <Box sx={{ flex: 2 }}>
              <Button
                variant="contained"
                sx={{
                  backgroundColor: "#002244",
                  color: "white",
                  width: "20%",
                  float: "right",
                }}
                onClick={handleAddRoute}
              >
                Add
              </Button>
            </Box>
          </Box>
          <Box m="2vh">
            <Box
              mt={
                window.innerHeight > 780
                  ? "-1vh"
                  : window.innerHeight > 700
                  ? "2vh"
                  : "1vh"
              }
              height={
                window.innerHeight > 780
                  ? "465px"
                  : window.innerHeight > 700
                  ? "415px"
                  : "390px"
              }
              sx={{
                "& .MuiDataGrid-root": {
                  border: "none",
                },
                "& .MuiDataGrid-cell": {
                  borderBottom: "none",
                  border: "none !important",
                },
                "& .name-column--cell": {
                  color: "black",
                  border: "none !important",
                },
                "& .MuiDataGrid-cell:focus-within": {
                  outline: "none !important",
                  border: "none !important",
                },
                "& .MuiDataGrid-cell:active": {
                  outline: "none !important",
                  border: "none !important",
                },
                "& .MuiDataGrid-columnHeaders": {
                  backgroundColor: "#444444",
                  color: "white",
                  border: "none !important",
                  borderBottom: "none",
                },
                "& .MuiDataGrid-columnHeaders:active": {
                  outline: "none !important",
                  border: "none !important",
                },
                "& .MuiDataGrid-virtualScroller": {
                  backgroundColor: "white",
                  border: "1px solid grey",
                  overflow: "hidden",
                },
                "& .MuiCheckbox-root": {
                  color: `${colors.greenAccent[200]} !important`,
                },
                "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                  color: `${colors.grey[100]} !important`,
                },
                "& .MuiDataGrid-footerContainer": {
                  borderTop: "none",
                  backgroundColor: "#444444",
                  color: "white !important",
                },
                "& .MuiDataGrid-footer .MuiDataGridPanel-footer": {
                  color: "white !important",
                },
                "& .MuiTablePagination-toolbar": {
                  color: "white",
                },
                "& .MuiTablePagination-actions": {
                  "& .MuiIconButton-root": {
                    color: "white",
                  },
                },
              }}
            >
              <DataGrid
                key={gridKey}
                rows={fetchedData}
                columns={columns}
                loading={isLoading}
                pageSize={6}
                disableColumnFilter
                rowCount={pagnation || 0}
                pagination
                getRowHeight={() =>
                  window.innerHeight > 780
                    ? 53
                    : window.innerHeight > 700
                    ? 44
                    : 41
                }
                getRowId={getRowId}
                getRowClassName={(params) =>
                  params.indexRelativeToCurrentPage % 2 === 0
                    ? "Mui-even"
                    : "Mui-odd"
                }
                localeText={{
                  noRowsLabel: isLoading
                    ? ""
                    : selectCheck.active
                    ? dataFetched && fetchedData.length === 0
                      ? "No Active Routes"
                      : ""
                    : dataFetched && fetchedData.length === 0
                    ? "No Inactive Routes"
                    : "",
                }}
                disableDensitySelector
                componentsProps={{
                  toolbar: {
                    csvOptions: { disableToolbarButton: true },
                    printOptions: { disableToolbarButton: true },
                  },
                }}
                components={{ Toolbar: GridToolbar }}
                paginationMode="server"
                onPageChange={handlePageChange}
              />
            </Box>
          </Box>
        </Box>
      )}
    </>
  );
};

export default Routemgmt;
