import { Box, useTheme } from "@mui/material";
import { tokens } from "../../theme";
import Header from "../../components/Header";
/*import { useLocation } from 'react-router-dom';*/
import { Navigate } from 'react-router-dom';
import {React} from "react";
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from "react";
import {active} from '../../activeslice';

const Dashboard = () => {
  const theme = useTheme();
  const isLoggedIn = useSelector((state) => state.auth.loggedIn);
  const dispatch= useDispatch();
  
  const colors = tokens(theme.palette.mode);
  useEffect(()=>{
  dispatch(active("Dashboard"));
  },[]);
  /*const location = useLocation();
  const data = location.state?.Data;*/
  
  if (!isLoggedIn) {
    return <Navigate to="/" />;
}



  /*const authorization={
        firstName: data.data.firstName,
        lastName: data.data.lastName,
        email: data.data.email,
        dashboardAccess: data.data.dashboardAccess===1?true:false,
        busSettingAccess: data.data.busSettingAccess===1?true:false,
        placeMgmtAccess: data.data.placeMgmtAccess===1?true:false,
        couponMgmtAccess: data.data.couponMgmtAccess===1?true:false,
        bookingMgmtAccess: data.data.bookingMgmtAccess===1?true:false,
        userMgmtAccess: data.data.userMgmtAccess===1?true:false,
  };
  console.log(data);*/

  return (
    <Box m="20px">
      {/* HEADER */}
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Header title="DASHBOARD" subtitle="Welcome to your dashboard" />
      </Box>
      <Box
        display="grid"
        gridTemplateColumns="repeat(12, 1fr)"
        gridAutoRows="140px"
        gap="20px"
      >
       </Box>
    </Box>
  );
};

export default Dashboard;
