import {React} from "react";
import { Box, Button, TextField } from "@mui/material";
import { Formik } from "formik";
import axios from 'axios';
import { useMode } from "./theme";
import EmailVerification from './EmailVerification';
import useMediaQuery from "@mui/material/useMediaQuery";
import './styles.css';
import Backdrop from '@mui/material/Backdrop';
import { Navigate } from "react-router-dom";
import { toast } from 'react-toastify';
import { Link,Dialog, DialogTitle, DialogContent,DialogActions} from "@mui/material";
import Header from "./components/Header";
import { useSelector} from 'react-redux';
import fig from './g232.png';
import { useState } from "react";
export default function Preapp(){
  //context api for color mode 
    const [theme, colorMode] = useMode();
    const isNonMobile = useMediaQuery("(min-width:600px)");
    //Fetched loggedin value from redux
    const isLoggedIn = useSelector((state) => state.auth.loggedIn);
    //created variable for otp vaerification and overlay effect
    const [showEmailVerification, setShowEmailVerification] = useState(false);
    const [overlayVisible, setOverlayVisible] = useState(false);
    //
    const[apicheckpassnemail,checkpassnemail]=useState(true);
    //variable for setting visibility of password dialog box
    const [showForgotPasswordDialog, setShowForgotPasswordDialog] = useState(false);
    // setting up the variables for vaerification of coorect input and taking inputs from user
    const [wrongmail, setMailVerify]=useState(true);
    const[disabledCheck,setDisabled]=useState(true);
    const[emverify,setemVrify]=useState({
      forgotemail:'',
      forgotemailverify:true,
    })
    const [state, setState] = useState({
      email: '',
      password: '',
      formfilled: false,
      emailcorrect: true,
      passwordcorrect: true,
      passinteract:false,
  });
 
  //function for sending password to the email of an user
  const passwordReset=(e)=>{
    e.preventDefault();
    const email=emverify.forgotemail;
    axios
          .post('https://api.freezebooking.in/v1/admin/forgotPassword',{email})
          .then(response => {
              const data = response.data;
              console.log(data);
              if(data.meta.status===true && data.meta.code===200)
              {
                toast.success(data.meta.message);
                setShowForgotPasswordDialog(false);
                setOverlayVisible(false);
                setMailVerify(true);
              }
              else{
                setMailVerify(false);

              }
          })
          .catch((error) => {
              console.error('API error:', error);
          });
  }

  
  // forgot password button functionality
  const handleForgotPasswordClick = () => {
    setShowForgotPasswordDialog(true);
    setOverlayVisible(true);
  };
  //cancel button functionality inside forgot password dialog box
  const handleForgotPasswordClose = () => {
    setShowForgotPasswordDialog(false);
    setOverlayVisible(false);
  };
  //stop click propagation on overlay
  const handleOverlayClick = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };
  //validating email on forgot password dialog box
  const handleInputPass=(e)=>{
    const { name, value } = e.target;
      setemVrify((prevState) => {
        const updatedState = {
          ...prevState,
          [name]: value,
        };
        const {forgotemail} = updatedState;
        const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        updatedState.forgotemailverify= emailRegex.test(forgotemail) || forgotemail.length === 0;
        if(updatedState.forgotemailverify===false)
        {
          setMailVerify(true);
        }

        return updatedState;
      });
  }

  //handle all the email password input changes and validation
    const handleInputChange = (e) => {
      const { name, value } = e.target;
      setState((prevState) => {
        const updatedState = {
          ...prevState,
          [name]: value,
        };
        const { email, password, passinteract } = updatedState;
        const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        updatedState.emailcorrect = emailRegex.test(email) || email.length === 0;
        if (email.length === 0 && password.length === 0) {
          updatedState.passinteract = false;
          updatedState.passwordcorrect = true;
          updatedState.formfilled = false;
        } else if (emailRegex.test(email) && password.length === 0 && !passinteract) {
          updatedState.passwordcorrect = true;
          checkpassnemail(true);
          updatedState.formfilled = false;
        } else if (emailRegex.test(email) && password.length === 0 && passinteract) {
          updatedState.passwordcorrect = false;
          checkpassnemail(true);
          updatedState.formfilled = false;
        } else if (email.length===0 && password.length > 0 ) {
          updatedState.passwordcorrect = true;
          updatedState.passinteract = true;
          updatedState.formfilled = false;
        } else if (password.length > 0 && emailRegex.test(email)) {
          updatedState.passinteract=true;
          updatedState.formfilled = true;
          updatedState.passwordcorrect=true;
        }
        return updatedState;
      });
    }
  //using callback functions to interact with operation done in emailverification page.
    const handleChildButtonClick = (message) => {
      setShowEmailVerification(message);
    };
  
//handlesubmission of form
const handleFormSubmit = (event) => {
  event.preventDefault();
  const { email, password } = state;
    axios
          .post('https://api.freezebooking.in/v1/admin/login',{ email, password })
          .then(response => {
              const data = response.data;
              console.log(data);
              if(data.meta.status===true && data.meta.code===200)
              {
                setShowEmailVerification(true);
                toast.success('Otp sent successfully to your Email ID');
              }
              else{
                if(data.meta.message==="Invalid Credentials!!")
                {
                checkpassnemail(false);
                }
                else{
                setDisabled(false);
                }
              }
              
          })
          .catch((error) => {
              console.error('API error:', error);
          });
};
//restricting user to go back to the login page if already loggen in
if (isLoggedIn && !showEmailVerification) {
  return <Navigate to="/dashboard" />;
}
   //css for forgot password dialog box
    const dialogStyles = {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      padding: theme => theme.spacing(2, 4, 3),
      backgroundColor:"white",
      boxShadow:"1px 1px 1px 1px aliceblue",
      color:theme.palette.neutral.light,
        }
  
    return(
    <>
        {overlayVisible && (
          <div
            className="modal-overlay"
            onClick={handleOverlayClick}
          ></div>
        )}
        <Box display="flex">
        </Box>
        {showEmailVerification ? (
        <EmailVerification email={state.email} onButtonClick={handleChildButtonClick}/>
    ) : (
      <div style={{ backgroundImage: `url(${fig})`,backgroundRepeat:"no-repeat",backgroundSize:"cover" }}>
        <Box
          display="flex"
          flexDirection="row"
          alignItems="center"
          justifyContent="center"
          minHeight="100vh"
          
        >
          <div style={{ marginTop:"-15vh", maxWidth: "40vw", width: "100%", padding: "20px",opacity:"1" }}>
            <Header title="LOGIN" subtitle="Login To Your Account" />
            <Formik
              onSubmit={handleFormSubmit}
            >
              {({
                values,
                errors,
                touched,
                handleBlur,
              }) => (
                <form autoComplete="off" onSubmit={handleFormSubmit}>
                  <Box
                    display="grid"
                    gap="30px"
                    sx={{
                      "& > div": {
                        gridColumn: isNonMobile ? "span 2" : "span 4",
                      },
                    }}
                  >
                    <TextField
                      fullWidth
                      type="email"
                      label="Username"
                      onBlur={handleBlur}
                      onChange={handleInputChange}
                      value={state.email}
                      name="email"
                      error={!state.emailcorrect}
                      helperText={state.emailcorrect ? '' : 'Invalid email'}
                      sx={{ gridColumn: "span 2" }}
                    />
                    <TextField
                      fullWidth
                      type="password"
                      label="Password"
                      onBlur={handleBlur}
                      onChange={handleInputChange}
                      value={state.password}
                      name="password"
                      error={!state.passwordcorrect} // Use your validation state for error
                      helperText={state.passwordcorrect ? '' : 'Password is required'}
                      sx={{ gridColumn: "span 2" }}
                    />
                  </Box>
                  {apicheckpassnemail?'':<span style={{color:"red"}}>Email or Password Entered Is Wrong</span>}
                  {disabledCheck?'':<span style={{color:"red"}}>Your Account has been Disabled, please contact the Admin.</span>}
                  <Box display="flex" justifyContent="center" mt="20px">
                    <Button type="submit"  variant="contained" disabled={!state.formfilled} sx={{
                    backgroundColor: "#002244",
                    color:"white",
                  }}>
                      LOGIN
                    </Button>
                  </Box>
                </form>
              )}
            </Formik>
            <Box display="flex" justifyContent="center" mt="10px" >
                <Link
                  onClick={handleForgotPasswordClick}
                  sx={{
                    cursor: "pointer",
                    color:"black",
                  }}
                >
            Forgot Password?
          </Link>
        </Box>
          </div>
        </Box>
        <Dialog
          open={showForgotPasswordDialog}
          onClose={handleForgotPasswordClose}
        >
          <Backdrop
  open={showForgotPasswordDialog}
  onClick={handleOverlayClick}
  style={{ zIndex: 9999 }} 
>
          <div style={dialogStyles}>
            <DialogTitle>Forgot Password?</DialogTitle>
            <DialogContent>
              <TextField
                label="Enter Your Email"
                onChange={handleInputPass}
                fullWidth
                margin="normal"
                name="forgotemail"
                value={emverify.forgotemail}
                error={!emverify.forgotemailverify}
                helperText={emverify.forgotemailverify ? '' : 'Invalid email'}
                sx={{
                gridColumn: "span 2",
               '& .MuiFilledInput-root': {
                backgroundColor: 'white', // Background color
                },
               '& .MuiInputLabel-root': {
                 color: 'black', // Label color
                },
               '& .MuiInputBase-input': {
                color: 'black', // Text color
                },
                '& .MuiOutlinedInput-notchedOutline': {
                  borderColor: 'black', // Outline color
                },
                }}
               />
               <div className={wrongmail ? "fixed-width-span hidden" : "fixed-width-span"}>
                Wrong Email Entered
               </div>

              <Button
                variant="contained"
                color="primary"
                fullWidth
                onClick={passwordReset}
                disabled={!emverify.forgotemailverify||emverify.forgotemail.length===0}
                sx={{
                  backgroundColor:"#002244",
                  "&.Mui-disabled": {
                    backgroundColor: "gray", 
                    color: "white", 
                  },
                }}
              >
                Send In Mail
              </Button>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleForgotPasswordClose} sx={{
    backgroundColor: "#002244",
    color: "white",
    '&:hover': {
      backgroundColor: 'red',
    },
  }}>Cancel</Button>
            </DialogActions>
          </div>
          </Backdrop>
        </Dialog>
        </div>
    )}
     </>
    );
}
