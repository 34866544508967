import { Box, Button, TextField, MenuItem} from "@mui/material";
import { Formik } from "formik";
import * as yup from "yup";
import useMediaQuery from "@mui/material/useMediaQuery";
import Header from "./components/Header";
import { useMode } from "./theme";
import axios from "axios";
import { toast } from 'react-toastify';
import { useSelector} from 'react-redux';
import { useState } from "react";


const Updateplacedetails = ({onButtonClick, data}) => {
  const isNonMobile = useMediaQuery("(min-width:600px)");
  const reduxdata = useSelector((state) => state.auth.data.authorization);
  const [theme, colorMode] = useMode();
  const initialValues = {
    placeId:data.placeId,
    biCommission: data.biCommission,
    biCommissionType: data.biCommissionType,
    ezCommission: data.ezCommission,
    ezCommissionType: data.ezCommissionType,
    btCommission: data.btCommission,
    btCommissionType: data.btCommissionType,
  };
  const buttonStyle={color:"white",
    backgroundColor:"#002244"}

    const convertToBoolean = (value) => !!value;

  const handleSubmit=(values,{resetForm})=>{
    const sddata=reduxdata;
    const axiosConfig = {
        headers: {
          'Authorization': sddata,
        },
      };
    const datatosend={
        placeId:data.placeId,
        ...values,
    };
    if(JSON.stringify(datatosend) === JSON.stringify(initialValues))
    {
        toast.success("Already updated");
        handleCancel();
    }
    else{
         axios
                .post('https://api.freezebooking.in/v1/admin/updatePlaceDetails',datatosend,axiosConfig)
                .then(response => {
                    const data = response.data;
                    if(data.meta.status===true && data.meta.code===200)
                    {   
                        resetForm();
                        toast.success("Successfully Updated Place Details");
                        handleCancel();
                    }
                    else{
                        console.log(data.meta.message);
                    }
                })
                .catch((error) => {
                    console.error('API error:', error);
                });
  }
}
  const handleCancel=()=>{
    onButtonClick(false);
}

  return (
    <Box m="20px">
      <Header title="Update Place Details" subtitle="" />

      <Formik
        onSubmit={handleSubmit}
        initialValues={initialValues}
        validationSchema={checkoutSchema}
      >
        {({
          values,
          errors,
          touched,
          handleBlur,
          handleChange,
          handleSubmit,
          setFieldValue,
        }) => (
          <form onSubmit={handleSubmit}>
            <Box
              display="grid"
              gap="30px"
              gridTemplateColumns="1fr"
              sx={{
                "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
              }}
            >
              <TextField
                fullWidth
                
                type="text"
                label="Place Name"
                value={data.placeName}
                name="Place Name"
                sx={{ gridColumn: "span 2" }}
                disabled
              />
              </Box>
              <Box
              display="grid"
              gap="30px"
              mt="3vh"
              gridTemplateColumns="repeat(4, minmax(0, 1fr))"
              sx={{
                "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
              }}
            >
              <TextField
                fullWidth
                
                type="number"
                label="BI Commission"
                onBlur={handleBlur}
                onChange={(e) => {
                    const { name, value } = e.target;
                    const parsedValue = Math.max(0, parseFloat(value)); 
                    setFieldValue(name, parsedValue);
                  }}
                value={values.biCommission}
                name="biCommission"
                error={!!touched.biCommission && !!errors.biCommission}
                helperText={touched.biCommission && errors.biCommission}
                sx={{ gridColumn: "span 2" }}
              />
              <TextField
                select
                fullWidth
                
                label="BI Commission Type"
                value={values.biCommissionType}
                onChange={handleChange}
                name="biCommissionType"
                error={!!touched.biCommissionType && !!errors.biCommissionType}
                helperText={touched.biCommissionType && errors.biCommissionType}
                sx={{ gridColumn: "span 2" }}
              >
                <MenuItem value="fixed">Fixed</MenuItem>
                <MenuItem value="percent">Percent</MenuItem>
              </TextField>
               <TextField
                fullWidth
                
                type="number"
                label="EZ Commission"
                onBlur={handleBlur}
                onChange={(e) => {
                    const { name, value } = e.target;
                    const parsedValue = Math.max(0, parseFloat(value)); 
                    setFieldValue(name, parsedValue);
                  }}
                value={values.ezCommission}
                name="ezCommission"
                error={!!touched.ezCommission && !!errors.ezCommission}
                helperText={touched.ezCommission && errors.ezCommission}
                sx={{ gridColumn: "span 2" }}
              />
               <TextField
                select
                fullWidth
                
                label="EZ Commission Type"
                value={values.ezCommissionType}
                onChange={handleChange}
                name="ezCommissionType"
                error={!!touched.ezCommissionType && !!errors.ezCommissionType}
                helperText={touched.ezCommissionType && errors.ezCommissionType}
                sx={{ gridColumn: "span 2" }}
              >
                <MenuItem value="fixed">Fixed</MenuItem>
                <MenuItem value="percent">Percent</MenuItem>
              </TextField>
               <TextField
                fullWidth
                
                type="number"
                label="BT Commission"
                onBlur={handleBlur}
                onChange={(e) => {
                    const { name, value } = e.target;
                    const parsedValue = Math.max(0, parseFloat(value)); 
                    setFieldValue(name, parsedValue);
                  }}
                value={values.btCommission}
                name="btCommission"
                error={!!touched.btCommission && !!errors.btCommission}
                helperText={touched.btCommission && errors.btCommission}
                sx={{ gridColumn: "span 2" }}
              />
               <TextField
                select
                fullWidth
                
                label="BT Commission Type"
                value={values.btCommissionType}
                onChange={handleChange}
                name="btCommissionType"
                error={!!touched.btCommissionType && !!errors.btCommissionType}
                helperText={touched.btCommissionType && errors.btCommissionType}
                sx={{ gridColumn: "span 2" }}
              >
                <MenuItem value="fixed">Fixed</MenuItem>
                <MenuItem value="percent">Percent</MenuItem>
              </TextField>
              
              
            </Box>
            <Box display="flex" justifyContent="center" mt="20px">
              <Button type="submit"  variant="contained" style={{...buttonStyle,marginRight:"2vw"}}>
                Update
              </Button>
              <Button type="button"  variant="contained" style={{...buttonStyle}} onClick={handleCancel}>
                Cancel
              </Button>
            </Box>
          </form>
        )}
      </Formik>
    </Box>
  );
};

const checkoutSchema = yup.object().shape({
    biCommission: yup
      .number()
      .typeError("BI Commission must be a number")
      .required("BI Commission is required")
      .min(0, "BI Commission must be a positive value or 0"),
    biCommissionType: yup.string().required("BI Commission Type is required"),
    ezCommission: yup
      .number()
      .typeError("EZ Commission must be a number")
      .required("EZ Commission is required")
      .min(0, "EZ Commission must be a positive value or 0"),
    ezCommissionType: yup.string().required("EZ Commission Type is required"),
    btCommission: yup
      .number()
      .typeError("BT Commission must be a number")
      .required("BT Commission is required")
      .min(0, "BT Commission must be a positive value or 0"),
    btCommissionType: yup.string().required("BT Commission Type is required"),
  });

export default Updateplacedetails;
