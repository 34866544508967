import React, { useState, useEffect } from 'react';
import TextField from '@mui/material/TextField';
import Modal from '@mui/material/Modal';
import { useTheme } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';

const NumberEditor = ({ value, onSubmit, rowId, onValueChange }) => {
  const [editedValue, setEditedValue] = useState(value);
  const [open, setOpen] = useState(false);
  const theme = useTheme();

  useEffect(() => {
    setEditedValue(value);
  }, [value]);

  const handleInputChange = (event) => {
    let newValue = event.target.value;
    if (newValue === '') {
      newValue='';
    } else if (parseInt(newValue, 10) < 1) {
      // If the new value is less than 1, set it to 1
      newValue = '1';
    }
    setEditedValue(newValue);
  };

  const handleBlur = () => {
    if (editedValue !== value) {
      if (editedValue === '') {
        setOpen(true);
      } else {
        const numericValue = parseInt(editedValue, 10);
        onValueChange(rowId, numericValue, value);
        onSubmit(numericValue);
      }
    }
  };

  return (
    <div style={{ display:"flex",justifyContent:"center",alignItems:"center" }}>
      <TextField
        type="number"
        value={editedValue}
        onChange={handleInputChange}
        onBlur={handleBlur}
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            const numericValue = parseInt(editedValue, 10);
            onValueChange(rowId, numericValue, value);
            onSubmit(numericValue);
          }
        }}
        InputProps={{
          inputMode: 'numeric',
          pattern: '[0-9]*',
          sx: { height:window.innerHeight>724?"40px":"30px" },
        }}
        sx={{ border: 'none', outline: 'none' }}
      />
      <Modal open={open}
        onClose={() => setOpen(false)}
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',}}>
      <Box
        sx={{
          width: 400,
          height: 200,
          bgcolor: 'background.paper',
          p: 2,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          flexDirection: 'column',
          border: '2px solid red',
          borderRadius: '10px',
        }}
      >
        <p style={{ color: 'red' }}>Priority cannot be empty!</p>
        <Button
          variant="contained"
          onClick={() => setOpen(false)}
          style={{ marginTop: '20px' }}
        >
          OK
        </Button>
      </Box>
    </Modal>
    </div>
  );
};

export default NumberEditor;
