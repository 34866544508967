import { Box, Button, ButtonGroup } from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { tokens } from "../../theme";
import { useTheme} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import { useEffect, useState, useMemo } from "react";
import { debounce } from "lodash";
import {
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  Checkbox,
  Input,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import GetAppIcon from "@mui/icons-material/GetApp";
import DeleteForeverOutlinedIcon from "@mui/icons-material/DeleteForeverOutlined";
import Modal from "@mui/material/Modal";
import axios from "axios";
import "../../styles.css";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import ArrowLeftIcon from "@mui/icons-material/ArrowLeft";
import Cancelbooking from "./CancelBooking";
import {active} from "../../activeslice";

const useStyles = makeStyles((theme) => ({
  filterButton: {
    height: "35px",
    width: "90px",
    minWidth: "90px",
    minHeight: "35px",
    fontWeight: 700,
    border: "1px solid grey",
    display: "inline-flex",
    paddingLeft: "5px",
    justifyContent: "center",
    alignItems: "center",
    marginTop: "2vh",
    cursor: "pointer",
    borderRadius: "2px",
  },
  tablemargin: {
    width: "15vw",
    minWidth: "120px",
  },
}));

const Contacts = () => {
  const theme = useTheme();
  const dispatch=useDispatch();
  const classes = useStyles();
  const colors = tokens(theme.palette.mode);
  const searchval = useSelector((state) => state.search.searchValue);
  const [currentPage, setCurrentPage] = useState(0);
  const cancelAccess = useSelector((state) => state.auth.data.cancelAccess);
  const memoizedCurrentPage = useMemo(() => currentPage, [currentPage]);
  const [selectedItems, setSelectedItems] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedPlatform, setSelectedPlatform] = useState("");
  const [items, setItems] = useState([]);
  const [gridKey, setGridKey] = useState(0);
  const reduxdata = useSelector((state) => state.auth.data.authorization);
  const memoizedReduxData = useMemo(() => reduxdata, [reduxdata]);
  const [modalUserData, setModalUserData] = useState();
  const [showCancel, setShowCancel] = useState();
  const [selectedUser, setSelectedUser] = useState(null);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [showOverlay, setShowOverlay] = useState(false);
  const [showActiveButton, setActiveButton] = useState({
    completed: false,
    upcoming: true,
    cancelled: false,
    searched: false,
    bookingFailed: false,
    paymentFailed: false,
  });
  const [pagnation, setPagination] = useState(null);
  const memoizedSearchVal = useMemo(() => searchval, [searchval]);
  const [fetchedData, setFetchData] = useState([]);
  const [dataFetched, setDataFetched] = useState(false);
  const getRowId = (row) => row.biSubBookingId;

  const columns = [
    /*{ field: "placeId", headerName: "ID", flex: 0.5, hide:true },
    { field: "registrarId", headerName: "Place name" },*/
    {
      field: "emailId",
      headerName: "Email ID",
      flex: 1.5,
    },
    ...(["completed", "upcoming", "cancelled"].includes(
      Object.keys(showActiveButton).find(
        (key) => showActiveButton[key] === true
      )
    )
      ? [
    {
      field: "platform",
      headerName: "Platform",
      flex: 0.7,
    },
    ]
     : []),

    ...(["completed", "upcoming", "cancelled"].includes(
      Object.keys(showActiveButton).find(
        (key) => showActiveButton[key] === true
      )
    )
      ? [
    {
      field: "bookingId",
      headerName: "Booking ID",
      flex: 0.7,
    },
     ]
     : []),
    {
      field: "journeyDate",
      headerName: "Journey Date",
      flex: 1,
    },
    ...(["completed", "upcoming", "cancelled"].includes(
      Object.keys(showActiveButton).find(
        (key) => showActiveButton[key] === true
      )
    )
      ? [
          {
            field: "busName",
            headerName: "Bus Name",
            flex: 1,
          },
        ]
      : []),

    ...(["completed", "upcoming", "cancelled"].includes(
      Object.keys(showActiveButton).find(
        (key) => showActiveButton[key] === true
      )
    )
      ? [
          {
            field: "pnrNumber",
            headerName: "PNR Number",
            flex: 1,
          },
        ]
      : []),

    {
      field: "fromPlaceName",
      headerName: "Source",
      flex: 0.7,
    },
    {
      field: "toPlaceName",
      headerName: "Destination",
      flex: 0.7,
    },
    ...(["completed", "upcoming", "cancelled"].includes(
      Object.keys(showActiveButton).find(
        (key) => showActiveButton[key] === true
      )
    )
      ? [
    {
      field: "view",
      headerName: "View",
      flex: 0.7,
      renderCell: (params) => {
        const handleView = (row) => {
          setSelectedUser(row);
          setModalUserData(row);
          console.log(row);
          setShowOverlay(true);
        };
        return (
          <a
            key={params.row.biSubBookingId}
            onClick={() => handleView(params.row)}
          >
            <VisibilityOutlinedIcon
              style={{ verticalAlign: "middle", marginRight: "3px" }}
            />
            View
          </a>
        );
      },
    },
  ]
  : []),
    ...(cancelAccess === 1 && showActiveButton.upcoming === true
      ? [
          {
            field: "cancel",
            headerName: "Cancel",
            flex: 1.3,
            renderCell: (params) => {
              const handleCancel = (row) => {
                setModalUserData(row);
                setShowCancel(true);
              };
              return (
                <a
                  key={params.row.biSubBookingId}
                  onClick={() => handleCancel(params.row)}
                >
                  <DeleteForeverOutlinedIcon
                    style={{ verticalAlign: "middle" }}
                  />
                  Cancel Booking
                </a>
              );
            },
          },
        ]
      : []),
  ];
  const handleDownload = () => {
    window.open(modalUserData.invoiceUrl, "_blank");
  };
  const handleClick = (e) => {
    setActiveButton((prev) => ({
      ...Object.fromEntries(Object.keys(prev).map((key) => [key, false])),
      [e.target.name]: true,
    }));
    setPagination(null);
    setCurrentPage(0);
    setGridKey((prev) => prev + 1);
  };

  const debouncedFetchData = useMemo(
    () =>
      debounce(
        (
          searchString,
          page,
          showActiveButton,
          selectedItems,
          selectedPlatform
        ) => {
          console.log(showActiveButton);
          setFetchData([]);
          const datatosend = {
            searchString: searchString,
            page: page,
            size: 7,
            bookingStatus: Object.keys(showActiveButton).find(
              (key) => showActiveButton[key] === true
            ),
            travellerList: selectedItems,
            platform: selectedPlatform,
          };
          console.log(datatosend);
          const axiosConfig = {
            headers: {
              Authorization: memoizedReduxData,
            },
          };
          axios
            .post(
              "https://api.freezebooking.in/v1/admin/getBooking",
              datatosend,
              axiosConfig
            )
            .then((response) => {
              const data = response.data;
              if (data.meta.status === true && data.meta.code === 200) {
                console.log("Successfully got data");
                setFetchData(data.data.bookingList);
                setDataFetched(true);
                setPagination(data.data.totalItems);
              } else {
                console.log("Error Occurred in API");
              }
            })
            .catch((error) => {
              console.error("API error:", error);
            })
            .finally(() => {
              setIsLoading(false);
            });
        },
        300
      ),
    []
  );

  const getTravellerList = useMemo(
    () =>
      debounce(() => {
        const axiosConfig = {
          headers: {
            Authorization: memoizedReduxData,
          },
        };
        axios
          .post(
            "https://api.freezebooking.in/v1/admin/getTravellerList",
            {},
            axiosConfig
          )
          .then((response) => {
            const data = response.data;
            if (data.meta.status === true && data.meta.code === 200) {
              console.log("Successfully got data");
              setItems(data.data.travellerList);
            } else {
              console.log("Error Occurred in API");
            }
          })
          .catch((error) => {
            console.error("API error:", error);
          });
      }, 300),
    []
  );

  useEffect(() => {
    setIsLoading(true);
    setDataFetched(false);
    debouncedFetchData(
      memoizedSearchVal,
      memoizedCurrentPage,
      showActiveButton,
      selectedItems,
      selectedPlatform
    );
  }, [
    memoizedSearchVal,
    debouncedFetchData,
    memoizedCurrentPage,
    showActiveButton,
    selectedItems,
    selectedPlatform,
  ]);

  useEffect(() => {
    getTravellerList();
    dispatch(active("Booking Management"));
  }, []);

  // Listen for window resize event
  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  const handlePageChange = (params) => {
    if (params !== currentPage) {
      setCurrentPage(params);
      debouncedFetchData(memoizedSearchVal, params, showActiveButton);
    }
  };
  const handleFilters = () => {
    if (document.querySelector("#licon").style.display === "none") {
      document.querySelector("#licon").style.display = "inline-block";
      document.querySelector("#ricon").style.display = "none";
      document.querySelector("#box1").style.display = "none";
      document.querySelector("#box2").style.display = "none";
    } else {
      document.querySelector("#licon").style.display = "none";
      document.querySelector("#ricon").style.display = "inline-block";
      document.querySelector("#box1").style.display = "inline-flex";
      document.querySelector("#box2").style.display = "inline-flex";
    }
  };
  const handleChange = (event) => {
    const value = event.target.value;
    setSelectedItems(value);
  };
  const handlePlatform = (e) => {
    const newValue = e.target.value;
    setSelectedPlatform((prevValue) =>
      prevValue === newValue ? "" : newValue
    );
  };
  const handleClearFilters = () => {
    setSelectedPlatform("");
  };
  const handleChildButtonClick = (message) => {
    setShowCancel(message);
    setModalUserData(null);
    setIsLoading(true);
    setDataFetched(false);
    debouncedFetchData(
      memoizedSearchVal,
      memoizedCurrentPage,
      showActiveButton,
      selectedItems,
      selectedPlatform
    );
  };
  return (
    <>
      {showCancel ? (
        <Cancelbooking
          data={modalUserData}
          onButtonClick={handleChildButtonClick}
        />
      ) : (
        <Box m="20px">
          <ButtonGroup style={{ width: "100%", color: "aliceblue" }}>
            <Box
              border={1}
              borderColor="white"
              borderRadius={5}
              style={{ flex: 1 }}
            >
              <Button
                name="upcoming"
                onClick={handleClick}
                style={{
                  width: "100%",
                  borderColor: "black",
                  minWidth: "125px",
                  backgroundColor: showActiveButton.upcoming
                    ? "whitesmoke"
                    : "#002244",
                  color: showActiveButton.upcoming ? "#002244" : "white",
                }}
              >
                Upcoming
              </Button>
            </Box>
            <Box
              border={1}
              borderColor="white"
              borderRadius={5}
              style={{ flex: 1 }}
            >
              <Button
                name="completed"
                onClick={handleClick}
                style={{
                  width: "100%",
                  minWidth: "125px",
                  borderColor: "black",
                  backgroundColor: showActiveButton.completed
                    ? "whitesmoke"
                    : "#002244",
                  color: showActiveButton.completed ? "#002244" : "white",
                }}
              >
                Completed
              </Button>
            </Box>
            <Box
              border={1}
              borderColor="white"
              borderRadius={5}
              style={{ flex: 1 }}
            >
              <Button
                name="cancelled"
                onClick={handleClick}
                style={{
                  width: "100%",

                  minWidth: "125px",
                  borderColor: "black",
                  backgroundColor: showActiveButton.cancelled
                    ? "whitesmoke"
                    : "#002244",
                  color: showActiveButton.cancelled ? "#002244" : "white",
                }}
              >
                Cancelled
              </Button>
            </Box>
            <Box
              border={1}
              borderColor="white"
              borderRadius={5}
              style={{ flex: 1, marginLeft: "50px" }}
            >
              <Button
                name="searched"
                onClick={handleClick}
                style={{
                  width: "100%",
                  minWidth: "125px",
                  borderColor: "black",
                  backgroundColor: showActiveButton.searched
                    ? "whitesmoke"
                    : "#002244",
                  color: showActiveButton.searched ? "#002244" : "white",
                }}
              >
                Searched
              </Button>
            </Box>
            <Box
              border={1}
              borderColor="white"
              borderRadius={5}
              style={{ flex: 1 }}
            >
              <Button
                name="bookingFailed"
                onClick={handleClick}
                style={{
                  width: "100%",
                  minWidth: "125px",

                  borderColor: "black",
                  backgroundColor: showActiveButton.bookingFailed
                    ? "whitesmoke"
                    : "#002244",
                  color: showActiveButton.bookingFailed ? "#002244" : "white",
                }}
              >
                Booking Failed
              </Button>
            </Box>
            <Box
              border={1}
              borderColor="white"
              borderRadius={5}
              style={{ flex: 1 }}
            >
              <Button
                name="paymentFailed"
                onClick={handleClick}
                style={{
                  width: "100%",
                  minWidth: "125px",
                  borderColor: "black",
                  backgroundColor: showActiveButton.paymentFailed
                    ? "whitesmoke"
                    : "#002244",
                  color: showActiveButton.paymentFailed ? "#002244" : "white",
                }}
              >
                Payment Failed
              </Button>
            </Box>
          </ButtonGroup>
          <Box className={classes.filterButton} onClick={handleFilters}>
            Filter By
            <ArrowLeftIcon id="licon" />
            <ArrowRightIcon id="ricon" style={{ display: "none" }} />
          </Box>
          <Box
            id="box1"
            style={{ marginLeft: "15px", width: "200px", display: "none" }}
          >
            <FormControl fullWidth>
              {selectedPlatform === "" && (
                <InputLabel shrink={false} id="single-select-label">
                  Platform
                </InputLabel>
              )}
              <Select
                labelId="single-select-label"
                id="single-select"
                value={selectedPlatform}
                onChange={handlePlatform}
                input={<Input id="single-select" />}
                renderValue={(selected) => selected || "Platform"}
              >
                <MenuItem value="ez">
                  <Checkbox checked={selectedPlatform === "ez"} />
                  ez
                </MenuItem>
                <MenuItem value="bi">
                  <Checkbox checked={selectedPlatform === "bi"} />
                  bi
                </MenuItem>
                <MenuItem value="bt">
                  <Checkbox checked={selectedPlatform === "bt"} />
                  bt
                </MenuItem>
                <MenuItem value="">
                  <Button onClick={handleClearFilters} color="primary">
                    Clear Filters
                  </Button>
                </MenuItem>
              </Select>
            </FormControl>
          </Box>
          <Box
            id="box2"
            style={{ marginLeft: "15px", width: "200px", display: "none" }}
          >
            <FormControl fullWidth>
              {selectedItems.length === 0 && (
                <InputLabel
                  id="multi-select-label"
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                  shrink={false}
                  htmlFor="multi-select"
                >
                  Traveller List
                </InputLabel>
              )}
              <Select
                labelId="multi-select-label"
                id="multi-select"
                multiple
                value={selectedItems}
                onChange={handleChange}
                renderValue={(selected) => selected.join(", ")}
                input={<Input id="multi-select" />}
              >
                {items.map((item) => (
                  <MenuItem key={item} value={item}>
                    <Checkbox checked={selectedItems.includes(item)} />
                    {item}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>

          <Box
            mt={window.innerHeight > 740 ? "1vh" : "0vh"}
            height={
              window.innerHeight > 724
                ? "515px"
                : window.innerHeight > 650
                ? "450px"
                : "420px"
            }
            sx={{
              "& .MuiDataGrid-root": {
                border: "none",
              },
              "& .MuiDataGrid-cell": {
                borderBottom: "none",
                border: "none !important",
              },
              "& .name-column--cell": {
                color: "black",
                border: "none !important",
              },
              "& .MuiDataGrid-cell:focus-within": {
                outline: "none !important",
                border: "none !important",
              },
              "& .MuiDataGrid-cell:active": {
                outline: "none !important",
                border: "none !important",
              },
              "& .MuiDataGrid-columnHeaders": {
                backgroundColor: "#444444",
                color: "white",
                border: "none !important",
                borderBottom: "none",
              },
              "& .MuiDataGrid-columnHeaders:active": {
                outline: "none !important",
                border: "none !important",
              },
              "& .MuiDataGrid-virtualScroller": {
                backgroundColor: "white",
                border: "1px solid grey",
                overflow: "hidden",
              },
              "& .MuiCheckbox-root": {
                color: `${colors.greenAccent[200]} !important`,
              },
              "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                color: `${colors.grey[100]} !important`,
              },
              "& .MuiDataGrid-footerContainer": {
                borderTop: "none",
                backgroundColor: "#444444",
                color: "white !important",
              },
              "& .MuiDataGrid-footer .MuiDataGridPanel-footer": {
                color: "white !important",
              },
              "& .MuiTablePagination-toolbar": {
                color: "white",
              },
              "& .MuiTablePagination-actions": {
                "& .MuiIconButton-root": {
                  color: "white",
                },
              },
            }}
          >
            <DataGrid
              key={gridKey}
              rows={fetchedData}
              columns={columns}
              pageSize={7}
              getRowHeight={() =>
                window.innerHeight > 724
                  ? 53
                  : window.innerHeight > 650
                  ? 44
                  : 39.5
              }
              disableColumnFilter
              loading={isLoading}
              rowCount={pagnation || 0}
              pagination
              disableDensitySelector
              componentsProps={{
                toolbar: {
                  csvOptions: { disableToolbarButton: true },
                  printOptions: { disableToolbarButton: true },
                },
              }}
              getRowClassName={(params) =>
                params.indexRelativeToCurrentPage % 2 === 0
                  ? "Mui-even"
                  : "Mui-odd"
              }
              getRowId={getRowId}
              components={{ Toolbar: GridToolbar }}
              paginationMode="server"
              localeText={{
                noRowsLabel: isLoading
                  ? ""
                  : dataFetched && fetchedData.length === 0
                  ? `${"Nothing "}${
                      ["bookingFailed", "paymentFailed"].includes(
                        Object.keys(showActiveButton).find(
                          (key) => showActiveButton[key] === true
                        )
                      )
                        ? "Failed"
                        : Object.keys(showActiveButton).find(
                            (key) => showActiveButton[key] === true
                          )
                    }`
                  : "",
                /*showActiveButton.upcoming
              ? dataFetched && fetchedData.length === 0
                ? "No Upcoming Bookings"
                : ""
              : showActiveButton.completed
              ? dataFetched && fetchedData.length === 0
                ? "No Completed Bookings"
                : ""
              : showActiveButton.cancelled
              ? dataFetched && fetchedData.length === 0 
                ? "No Cancelled Bookings"
                : ""
              : ""*/
              }}
              onPageChange={handlePageChange}
            />
          </Box>
          {selectedUser && (
            <Modal
              open={selectedUser !== null}
              onClose={() => {
                setSelectedUser(null);
                setModalUserData([]);
                setShowOverlay(false);
              }}
              aria-labelledby="Booking Details"
              aria-describedby="Booking Description"
            >
              <Box
                sx={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                  overflowX: "auto",
                  "&::-webkit-scrollbar": {
                    width: "6px",
                  },
                  width: "80vw",
                  height: "80vh",
                  bgcolor: "background.paper",
                  border: "2px solid grey",
                  boxShadow: 24,
                  minWidth: "700px",
                  minHeight: "500px",

                  fontSize: windowWidth > 1400 ? "14px" : "12px",
                }}
              >
                <Box
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    fontWeight: "bold",
                  }}
                >
                  <h1 style={{ flexGrow: 1, textAlign: "center" }}>
                    Ticket Info
                  </h1>
                  <span style={{ display: "flex", alignItems: "center" }}>
                    <GetAppIcon
                      style={{ cursor: "pointer" }}
                      onClick={handleDownload}
                    />
                    <span
                      style={{
                        paddingLeft: "5px",
                        cursor: "pointer",
                        marginRight: "20px",
                      }}
                      onClick={handleDownload}
                    >
                      Download Invoice
                    </span>
                  </span>
                </Box>
                <Box
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    margin: "0 2vw 0",
                    minWidth: "400px",
                  }}
                >
                  <Box style={{ display: "flex", flex: "3" }}>
                    <Box
                      style={{
                        display: "inline-block",
                        fontWeight: "bold",
                        textAlign: "right",
                        flex: "1",
                      }}
                    >
                      <dl
                        style={{
                          maxWidth: "200px",
                          textOverflow: "ellipsis",
                          whiteSpace: "nowrap",
                        }}
                      >
                        <dd>PNR Number :</dd>
                        <dd>Date of Journey:</dd>
                        <dd>Service Start Place :</dd>
                        <dd>Service Start Time :</dd>
                        <dd>Trip Code :</dd>
                        <dd>No. of Seats :</dd>
                        <dd>Pickup Point :</dd>
                      </dl>
                    </Box>
                    <Box
                      style={{
                        display: "inline-block",
                        marginLeft: "-30px",
                        flex: "2",
                      }}
                    >
                      <dl>
                        <dd>{modalUserData.pnrNumber}</dd>
                        <dd>{modalUserData.journeyDate}</dd>
                        <dd>{modalUserData.fromPlaceName}</dd>
                        <dd>{modalUserData.boardingTime}</dd>
                        <dd>{modalUserData.tripCode}</dd>
                        <dd>{modalUserData.passengerList.length}</dd>
                        <dd>{modalUserData.boardingPointName}</dd>
                      </dl>
                    </Box>
                  </Box>
                  <Box
                    style={{
                      display: "flex",
                      flex: "3",
                      marginLeft: "-70px",
                      minWidth: "350px",
                    }}
                  >
                    <Box
                      style={{
                        display: "inline-block",
                        fontWeight: "bold",
                        textAlign: "right",
                        flex: "1",
                      }}
                    >
                      <dl
                        style={{
                          maxWidth: "200px",
                          textOverflow: "ellipsis",
                          whiteSpace: "nowrap",
                        }}
                      >
                        <dd>Platform :</dd>
                        <dd>Route No :</dd>
                        <dd>Service End Place :</dd>
                        <dd>Passenger Drop Time :</dd>
                        <dd>Class of Service :</dd>
                        <dd>Drop Point :</dd>
                      </dl>
                    </Box>
                    <Box
                      style={{
                        display: "inline-block",
                        marginLeft: "-30px",
                        flex: "2",
                      }}
                    >
                      <dl>
                        <dd>{modalUserData.platform}</dd>
                        <dd>
                          {modalUserData.routeNo === ""
                            ? "-"
                            : modalUserData.routeNo}
                        </dd>
                        <dd>{modalUserData.toPlaceName}</dd>
                        <dd>{modalUserData.boardingTime}</dd>
                        <dd>{modalUserData.className}</dd>
                        <dd>{modalUserData.droppingName}</dd>
                      </dl>
                    </Box>
                  </Box>
                </Box>
                <Box>
                  <h3
                    style={{
                      marginLeft: "7.1vw",
                      marginBottom: "-5px",
                      color: "#002244",
                    }}
                  >
                    Passenger Details
                  </h3>
                  <table style={{ margin: "1vh 0 0 7vw" }}>
                    <th>Passenger Name</th>
                    <th className={classes.tablemargin}>Passenger Gender</th>
                    <th className={classes.tablemargin}>Passenger Age</th>
                    <th className={classes.tablemargin}>Email</th>
                    {modalUserData.passengerList.map((id, index) => (
                      <tr>
                        <td key={index}>{id.passengerName}</td>
                        <td
                          key={index}
                          className={classes.tablemargin}
                          style={{ textAlign: "center" }}
                        >
                          {id.passengerGender}
                        </td>
                        <td
                          key={index}
                          className={classes.tablemargin}
                          style={{ textAlign: "center" }}
                        >
                          {id.passengerAge}
                        </td>
                        <td
                          key={index}
                          className={classes.tablemargin}
                          style={{ textAlign: "center" }}
                        >
                          {modalUserData.emailId}
                        </td>
                      </tr>
                    ))}
                  </table>
                </Box>
                <h3 style={{ marginLeft: "7.1vw", marginBottom: "-15px" }}>
                  Fare Details :
                </h3>
                <h4 style={{ marginLeft: "7.1vw", display: "inline-block" }}>
                  Coupon Used :{" "}
                  {modalUserData.couponCode === null
                    ? "None"
                    : modalUserData.couponCode}
                </h4>
                <h4 style={{ marginLeft: "5vw", display: "inline-block" }}>
                  Coupon Discount : {modalUserData.couponDiscount}
                </h4>
                <br />
                <h4
                  style={{
                    marginLeft: "7.1vw",
                    display: "inline-block",
                    marginTop: "-20px",
                    marginBottom: "40px",
                  }}
                >
                  Total Fare : {modalUserData.finalFare}
                </h4>
              </Box>
            </Modal>
          )}
          {showOverlay && (
            <div
              style={{
                position: "fixed",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
                backgroundColor: "rgba(0, 0, 0, 0.8)",
                zIndex: 9,
              }}
            ></div>
          )}
        </Box>
      )}
    </>
  );
};

export default Contacts;
