import { createSlice } from '@reduxjs/toolkit';
const initialState = {
  activeValue: "",
  updatedData:false,
};
const activeslice = createSlice({
  name: 'active',
  initialState,
  reducers: {
    active: (state, action) => {
      state.activeValue = action.payload;
    },
    updateData:(state, action) => {
        state.updatedData = action.payload;
      },
    clearData:(state)=>{
        state.activeValue="";
    },
    clearDatas:(state)=>{
        state.updatedData=false;
    }
  }
});

export const { active,updateData,clearData,clearDatas} = activeslice.actions;
export default activeslice.reducer;
