import { Box,Button,ButtonGroup,TextField, FormControlLabel, Checkbox, Typography,MenuItem } from "@mui/material";
import { useDispatch,useSelector} from 'react-redux';
import useMediaQuery from "@mui/material/useMediaQuery";
import { Formik } from "formik";
import * as yup from "yup";
import { useState} from "react";
import { toast } from 'react-toastify';
import axios from 'axios';
import Header from "../../components/Header";
import { updateData } from '../../activeslice';

const EditCoupon = ({onButtonClick,data}) => {
  const reduxdata = useSelector((state) => state.auth.data.authorization);
  const checkUpdate = useSelector((state) => state.active.updatedData);
  const dispatch=useDispatch();
  const isNonMobile = useMediaQuery("(min-width:600px)");
  const [imageCheck, setImage] = useState({
    image:null,
    imageUrl:null,
  });
  console.log(data);
  const date = data.expiryDate ? new Date(data.expiryDate) : new Date(); 
  const formattedDate = date.toISOString().split("T")[0];
  const initialValues=
  {
    couponCode: data.couponCode,
    couponDescription:data.couponDescription===null?"":data.couponDescription,
    couponImage: data.couponImage===null?"":data.couponImage,
    couponValue: data.couponValue,
    couponType: data.couponType,
    expiryDate: formattedDate,
    units: data.units===null?"":data.units,
    termsAndCondition: data.termsAndCondition===null?"":data.termsAndCondition,
  };
  const handleCancel=()=>{
    if (checkUpdate===true) {
      const confirmation = window.confirm(
        "Do you wish to leave the page without saving the changes? Click OK to leave the page."
      );
      if (!confirmation) {
        return;
      }
      else
      {
        onButtonClick(false);
        dispatch(updateData(false));
      }
}
else
{
  onButtonClick(false);
        dispatch(updateData(false));
}
  }
const buttonStyle={color:"white",
backgroundColor:"#002244"};
const today = new Date().toISOString().split("T")[0];

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setImage({image:file});
    console.log(file);
    const sddata = reduxdata;
    const formData = new FormData();
    formData.append('file',file);
    const axiosConfig = {
      headers: {
        Authorization: sddata,
       'Content-Type': 'multipart/form-data',
      },
    };
    axios
      .post('https://api.freezebooking.in/v1/admin/uploadFile', formData, axiosConfig)
      .then((response) => {
        const data = response.data;
        if (data.meta.status === true && data.meta.code === 200) {
          toast.success('Successfully Uploaded Image');
          toast.success('Click Update to Save the Changes');
          setImage(prevState => ({
            ...prevState,
            imageUrl: data.data.fileNameUrl,
          }));
          dispatch(updateData(true));   
        } else {
          console.log(data.meta.message);
        }
      })
      .catch((error) => {
        console.error('API error:', error);
      });
  };
  const handleSubmit=(values,{resetForm})=>{  
    const sddata=reduxdata;
    const axiosConfig = {
        headers: {
          'Authorization': sddata,
        },
      };
    const datatosend={
      ...values,
      couponImage:imageCheck.imageUrl,
      couponId:data.couponId,
    };
    Object.keys(datatosend).forEach((key) => {
      if (datatosend[key] === '') {
        datatosend[key] = null;
      }});
    console.log(datatosend);
        axios
                .post('https://api.freezebooking.in/v1/admin/updateCoupon',datatosend,axiosConfig)
                .then(response => {
                    const data = response.data;
                    if(data.meta.status===true && data.meta.code===200)
                    {   
                        resetForm();
                        toast.success("Successfully Updated Coupon");
                        setImage({
                          image:null,
                        });
                        dispatch(updateData(false)); 
                        onButtonClick(false);
        
                    }
                    else{
                        console.log(data.meta.message);
                    }
                })
                .catch((error) => {
                    console.error('API error:', error);
                });
  };
return(   
<Box m="20px">
  <Box display="flex" justifyContent="space-between">
  <Header title="Update Coupon" subtitle="" style={{display:"flex",gridColumn: "span 2"}}/>
  <Box sx={{ gridColumn: "span 2", display: "inline-block", alignItems:"center",fontWeight:"bold",margin:"1vh 3vh 0 0"  }}>
    <label htmlFor="contained-button-file" style={{ marginRight: "10px" }}>
      Coupon Image
    </label>
    <input
      accept="image/*"
      id="contained-button-file"
      type="file"
      style={{ display: 'none' }}
      onChange={handleFileChange}
    />
    <label htmlFor="contained-button-file">
      <Button variant="contained" component="span">
        Upload
      </Button>
      <Box style={{backgroundColor:"grey", display:"flex", justifyContent:"center"}} mt="1vh" mb="1vh">{imageCheck.image && <p>{imageCheck.image.name}</p>}</Box>
    </label>
  </Box>
  </Box>
  <Formik
    onSubmit={handleSubmit}
    initialValues={initialValues}
    validationSchema={checkoutSchema}
  >
    {({
      values,
      errors,
      touched,
      handleBlur,
      handleChange,
      handleSubmit,
    }) => (
      <form onSubmit={handleSubmit}>
        <Box
          display="grid"
          gap="30px"
          gridTemplateColumns="repeat(4, minmax(0, 1fr))"
          sx={{
            "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
          }}
        > 
          <TextField
            fullWidth
            
            type="text"
            label="Coupon Code"
            onBlur={handleBlur}
            onChange={handleChange}
            value={values.couponCode}
            name="couponCode"
            error={!!touched.couponCode && !!errors.couponCode}
            helperText={touched.couponCode && errors.couponCode}
            sx={{ gridColumn: "span 2" }}
          />
          <TextField
            fullWidth
            
            type="text"
            label="Coupon Description"
            onBlur={handleBlur}
            onChange={handleChange}
            value={values.couponDescription}
            name="couponDescription"
            error={!!touched.couponDescription && !!errors.couponDescription}
            helperText={touched.couponDescription && errors.couponDescription}
            sx={{ gridColumn: "span 2" }}
          />
          <TextField
            fullWidth
            
            type="text"
            label="Coupon Value"
            onBlur={handleBlur}
            onChange={handleChange}
            value={values.couponValue}
            name="couponValue"
            error={!!touched.couponValue && !!errors.couponValue}
            helperText={touched.couponValue && errors.couponValue}
            sx={{ gridColumn: "span 2" }}
          />
          <TextField
                select
                fullWidth
               
                label="Coupon Type"
                value={values.couponType}
                onChange={handleChange}
                name="couponType"
                error={!!touched.couponType && !!errors.couponType}
                helperText={touched.couponType && errors.couponType}
                sx={{ gridColumn: "span 2" }}
              >
                <MenuItem value="fixed">Fixed</MenuItem>
                <MenuItem value="percentage">Percentage</MenuItem>
              </TextField>
              <TextField
              id="expiryDate"
              label="Expiry Date"
              type="date"
              inputProps={{ min: today }}
              value={values.expiryDate}
              onChange={handleChange}
              InputLabelProps={{
                shrink: true,
              }}
              fullWidth
              sx={{ gridColumn: "span 2" }}
              error={touched.expiryDate && Boolean(errors.expiryDate)}
              helperText={touched.expiryDate && errors.expiryDate}
            />
            <TextField
            fullWidth
            type="number"
            inputProps={{ min: 1 }}
            label="Total Units"
            onBlur={handleBlur}
            onChange={handleChange}
            value={values.units}
            name="units"
            error={!!touched.units && !!errors.units}
            helperText={touched.units && errors.units}
            sx={{ gridColumn: "span 2" }}
          />
          <TextField
          fullWidth
          multiline
          rows={4} // Adjust the number of rows to fit your requirements
          
          type="text"
          label="Terms and Conditions"
          onBlur={handleBlur}
          onChange={handleChange}
          value={values.termsAndCondition}
          name="termsAndCondition"
          error={!!touched.termsAndCondition && !!errors.termsAndCondition}
          helperText={touched.termsAndCondition && errors.termsAndCondition}
          sx={{ gridColumn: "span 4" }}
        />
        </Box>
        <Box display="flex" justifyContent="center" mt="20px">
          <Button type="submit"  variant="contained" style={{...buttonStyle,marginRight:"2vw"}}>
            Update
          </Button>
          <Button type="button"  variant="contained" style={{...buttonStyle}} onClick={handleCancel}>
            Cancel
          </Button>
        </Box>
      </form>
    )}
  </Formik>
</Box>
 )
};
const checkoutSchema = yup.object().shape({
  couponCode: yup.string().matches(/^[\w\s]+$/, 'Coupon Code should contain only alphanumeric characters and spaces').required("Required"),
  couponDescription: yup.string().matches(/^[a-zA-Z0-9]*$/, 'Coupon Description should contain only alphanumeric characters'),
  couponValue: yup.number().typeError("Coupon Value should be a number").required("Required"),
  units: yup.number().typeError("Total Units should be a number").nullable(),
  couponType: yup.string().required("Required"),
  expiryDate: yup
    .date()
    .transform((curr, orig) => (orig === "" ? undefined : curr))
    .required("Expiry Date is required"),
  termsAndCondition: yup.string().matches(/^[a-zA-Z0-9]*$/, 'Terms and Conditions should contain only alphanumeric characters').nullable(),
});
export default EditCoupon;