import { Box, useTheme, Button } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { tokens } from "../../theme";
import Header from "../../components/Header";
import axios from "axios";
import { FormControlLabel, Checkbox } from "@mui/material";
import NumberEditor from "../../NumberEditor";
import { useEffect, useState, useMemo, useCallback } from "react";
import { debounce } from "lodash";
import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-toastify";
import Modal from "@mui/material/Modal";
import { updateData,active } from "../../activeslice";
import "../../styles.css";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  main: {
    color: "seagreen",

    "&.Mui-checked": {
      color: "seagreen",
    },
  },
}));
const Team = () => {
  const theme = useTheme();
  const classes = useStyles();
  const colors = tokens(theme.palette.mode);
  const reduxdata = useSelector((state) => state.auth.data);
  const memoizedReduxData = useMemo(() => reduxdata, [reduxdata]);
  const [busSettingsData, setBusSettingsData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [apibussettingsdata, setapibussettingsdata] = useState([]);
  const dispatch = useDispatch();
  const getRowId = (row) => row.settingId;
  const [modalOpen, setModalOpen] = useState(false);

  const columns = [
    {
      field: "settingId",
      headerName: "ID",
      flex: 1,
    },
    {
      field: "busPlatformName",
      headerName: "Operator Name",
      flex: 1,
    },
    {
      field: "enabled",
      headerName: "Enabled",
      flex: 1,
      renderCell: (params) => {
        const checked = params.row.enabled === "1" ? true : false;
        return (
          <FormControlLabel
            control={
              <Checkbox
                className={classes.main}
                sx={{
                  color: checked ? "seagreen" : "black",
                  "&.Mui-checked": {
                    color: "seagreen",
                  },
                  "&:hover": {
                    backgroundColor: "transparent",
                  },
                }}
                checked={checked}
                onChange={() => handleToggleEnabled(params.row.settingId)}
              />
            }
            label=""
          />
        );
      },
    },
    {
      field: "priority",
      headerName: "Priority",
      flex: 1,
      renderCell: (params) => {
        return (
          <NumberEditor
            value={params.row.priority}
            onSubmit={(value) => {
              params.api.updateRows([{ ...params.row, priority: value }]);
              console.log(busSettingsData);
            }}
            rowId={params.row.settingId}
            onValueChange={handleNumberEditorValueChange}
            style={{ border: "none", outline: "none" }}
          />
        );
      },
    },
  ];

  const fetchBusSettings = async () => {
    setIsLoading(true);
    const sddata = reduxdata.authorization;
    const axiosConfig = {
      headers: {
        Authorization: sddata,
      },
    };
    try {
      const response = await axios.post(
        "https://api.freezebooking.in/v1/admin/getBusSetting",
        {},
        axiosConfig
      );
      const data = response.data;
      console.log(data);
      if (data.meta.status === true && data.meta.code === 200) {
        console.log(data.data);
        setBusSettingsData(data.data.platformDetails);
        setapibussettingsdata(data.data.platformDetails);
        setIsLoading(false);
      }
    } catch (error) {
      console.error("API error:", error);
    }
  };

  const debouncedFetchBusSettings = useCallback(
    debounce(fetchBusSettings, 300),
    [memoizedReduxData]
  );

  useEffect(() => {
    debouncedFetchBusSettings();
      dispatch(active("Bus Settings"));
  }, [debouncedFetchBusSettings]);

  const handleToggleEnabled = (rowId) => {
    const updatedData = busSettingsData.map((row) =>
      row.settingId === rowId
        ? { ...row, enabled: row.enabled === "1" ? "0" : "1" }
        : row
    );
    setBusSettingsData(updatedData);
  };
  const checkDataChange = (updatedData) => {
    if (JSON.stringify(updatedData) !== JSON.stringify(apibussettingsdata)) {
      dispatch(updateData(true));
    } else {
      dispatch(updateData(false));
    }
  };
  useEffect(() => {
    checkDataChange(busSettingsData);
  }, [busSettingsData]);

  const handleNumberEditorValueChange = (rowId, newValue, value) => {
    const existingRow = busSettingsData.find(
      (row) => row.priority === newValue
    );
    if (existingRow) {
      const updatedData = busSettingsData.map((row) =>
        row.settingId === existingRow.settingId
          ? { ...row, priority: value }
          : row.settingId === rowId
          ? { ...row, priority: newValue }
          : row
      );
      setBusSettingsData(updatedData);
    } else {
      const updatedData = busSettingsData.map((row) =>
        row.settingId === rowId ? { ...row, priority: newValue } : row
      );
      setBusSettingsData(updatedData);
    }
  };

  const handleUpdateClick = async (e) => {
    e.preventDefault();
    const updatedBusData = busSettingsData;
    if (
      updatedBusData.some(
        (row) =>
          !row.settingId ||
          !row.busPlatformName ||
          row.enabled === null ||
          row.priority === null ||
          row.priority === ""
      )
    ) {
      setModalOpen(true);
      return;
    }
    const datatosend = {
      platformDetails: updatedBusData,
    };
    console.log(datatosend);
    const axiosConfig = {
      headers: {
        Authorization: reduxdata.authorization,
      },
    };
    axios
      .post(
        "https://api.freezebooking.in/v1/admin/updateBusSetting",
        datatosend,
        axiosConfig
      )
      .then((response) => {
        const data = response.data;
        if (data.meta.status === true && data.meta.code === 200) {
          toast.success("Data Updated Successfully");
          dispatch(updateData(false));
        } else {
          console.log("Error Occurred in API");
        }
      })
      .catch((error) => {
        console.error("API error:", error);
      });
  };

  return (
    <Box m="20px">
      <Header title="Bus Settings" subtitle="Managing the Bus Operators" />
      <Box
        m="40px 0 0 0"
        height={window.innerHeight > 724 ? "550px" : "410px"}
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
            width: "80%",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
            border: "none !important",
          },
          "& .name-column--cell": {
            color: "black",
            border: "none !important",
          },
          "& .MuiDataGrid-cell:focus-within": {
            outline: "none !important",
            border: "none !important",
          },
          "& .MuiDataGrid-cell:active": {
            outline: "none !important",
            border: "none !important",
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: "#444444",
            color: "white",
            borderBottom: "none !important",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: "white",
            border: "1px solid grey",
            overflow: "hidden",
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none !important",
            backgroundColor: colors.blueAccent[700],
            display: "none",
          },
        }}
      >
        <DataGrid
          loading={isLoading}
          style={{ marginLeft: "10%", height: "70%" }}
          rows={busSettingsData}
          getRowId={getRowId}
          getRowClassName={(params) =>
            params.indexRelativeToCurrentPage % 2 === 0 ? "Mui-even" : "Mui-odd"
          }
          columns={columns}
          getRowHeight={() => (window.innerHeight > 724 ? 53 : 42)}
          localeText={{
            noRowsLabel: isLoading ? "" : "",
          }}
        />
        <Box display="flex" justifyContent="center" m="20px">
          <Button
            variant="contained"
            sx={{ backgroundColor: "#002244", color: "white" }}
            onClick={handleUpdateClick}
          >
            Update
          </Button>
        </Box>
      </Box>
      <Modal
        open={modalOpen}
        onClose={() => setModalOpen(false)}
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Box
          sx={{
            width: 400,
            height: 200,
            bgcolor: "background.paper",
            p: 2,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
            border: "2px solid",
            borderRadius: "10px",
          }}
        >
          <p style={{ color: "red" }}>One or more values are left empty!</p>
          <Button
            variant="contained"
            color="primary"
            onClick={() => setModalOpen(false)}
            style={{ marginTop: "20px" }}
          >
            OK
          </Button>
        </Box>
      </Modal>
    </Box>
  );
};

export default Team;
