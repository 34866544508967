import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  searchValue: "",
  buttonClick:false,
};

const searchSlice = createSlice({
  name: 'search',
  initialState,
  reducers: {
    search: (state, action) => {
      state.searchValue = action.payload;
    },
    clearSearch:(state)=>{
        state.searchValue="";
    },
    checkClick:(state,action)=>{
      state.buttonClick=action.payload;
    }
  }
});

export const { search, clearSearch, checkClick} = searchSlice.actions;
export default searchSlice.reducer;
