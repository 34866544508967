import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    loggedIn: false,
    data:{
        firstName: "",
        lastName: "",
        email: "",
        dashboardAccess: null,
        busSettingAccess: null,
        placeMgmtAccess: null,
        couponMgmtAccess: null,
        bookingMgmtAccess: null,
        userMgmtAccess: null,
        routeMgmtAccess: null,
        cancelAccess:null,
        authorization:"",
    }
};

const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        login: (state) => {
            state.loggedIn = true;
        },
        logout: (state) => {
            state.loggedIn = false;
        },
        setData: (state,action)=>{
            state.data=action.payload;
        },
        clearAll:(state)=>{
            state.data={
                firstName: "",
                lastName: "",
                email: "",
                dashboardAccess: null,
                busSettingAccess: null,
                placeMgmtAccess: null,
                couponMgmtAccess: null,
                bookingMgmtAccess: null,
                userMgmtAccess: null,
                authorization:"",
            }
        }
    },
});
export const { login, logout, setData, clearAll } = authSlice.actions;
export default authSlice.reducer;