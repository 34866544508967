import { configureStore, combineReducers } from '@reduxjs/toolkit';
import authReducer from './authslice';
import searchReducer from './searchslice';
import activeReducer from './activeslice'; // Import your new reducer

import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from 'redux-persist';
import storage from 'redux-persist/lib/storage';

const persistConfigAuth = {
  key: 'auth',
  storage,
};

const persistConfigSearch = {
  key: 'search',
  storage,
};
const persistConfigActive = {
  key: 'active',
  storage,
};

const persistedAuthReducer = persistReducer(persistConfigAuth, authReducer);
const persistedSearchReducer = persistReducer(persistConfigSearch, searchReducer);
const persistedActiveReducer = persistReducer(persistConfigActive, activeReducer);

const rootReducer = combineReducers({
  auth: persistedAuthReducer,
  search: persistedSearchReducer,
  active: persistedActiveReducer,
});

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
});

const persistor = persistStore(store);

export default store;
export { persistor };
