import { Box, Button, TextField, MenuItem, Autocomplete } from "@mui/material";
import { useSelector } from "react-redux";
import useMediaQuery from "@mui/material/useMediaQuery";
import { Formik } from "formik";
import * as yup from "yup";
import { toast } from "react-toastify";
import axios from "axios";
import { debounce } from "lodash";
import Header from "./components/Header";
import { useMemo, useState, useEffect } from "react";

const AddNewRoute = ({ onButtonClick }) => {
  const reduxdata = useSelector((state) => state.auth.data.authorization);
  const isNonMobile = useMediaQuery("(min-width:600px)");
  const handleCancel = () => {
    onButtonClick(false);
  };

  const buttonStyle = { color: "white", backgroundColor: "#002244" };
  const today = new Date().toISOString().split("T")[0];
  const [place, setPlace] = useState({
    fromPlaceId: "",
    toPlaceId: "",
    fPId: null,
    tPId: null,
  });
  const [fetchedData, setFetchData] = useState([]);
  const initialValues = {
    ezCommission: null,
    ezCommissionType: "",
    biCommission: null,
    biCommissionType: "",
    btCommission: null,
    btCommissionType: "",
    toDate: "",
    fromDate: "",
  };

  const handleSubmit = (values, { resetForm }) => {
    const sddata = reduxdata;
    const axiosConfig = {
      headers: {
        Authorization: sddata,
      },
    };
    const datatosend = {
      ...values,
      fromPlaceId: place.fPId,
      toPlaceId: place.tPId,
    };

    axios
      .post(
        "https://api.freezebooking.in/v1/admin/createRoutePricing",
        datatosend,
        axiosConfig
      )
      .then((response) => {
        const data = response.data;
        if (data.meta.status === true && data.meta.code === 200) {
          resetForm();
          toast.success("Successfully Added New Route");
          onButtonClick(false);
        } else {
          console.log(data.meta.message);
        }
      })
      .catch((error) => {
        console.error("API error:", error);
      });
  };
  const debouncedFetchData = useMemo(
    () =>
      debounce((searchString) => {
        const datatosend = {
          searchString: searchString || "",
          page: 0,
          size: 16026,
        };
        const axiosConfig = {
          headers: {
            Authorization: reduxdata,
          },
        };
        axios
          .post(
            "https://api.freezebooking.in/v1/admin/getPlaceDetails",
            datatosend,
            axiosConfig
          )
          .then((response) => {
            const data = response.data;
            if (data.meta.status === true && data.meta.code === 200) {
              console.log("Successfully got places");
              setFetchData(data.data.placeDetails);
            } else {
              console.log("Error Occurred in API");
            }
          })
          .catch((error) => {
            console.error("API error:", error);
          });
      }, 300),
    []
  );

  useEffect(() => {
    debouncedFetchData("");
  }, [debouncedFetchData]);

  const handleSelect = (value, fieldName) => {
    if (fieldName === "toPlaceId") {
      setPlace((prevPlace) => ({
        ...prevPlace,
        toPlaceId: value,
        tPId:
          fetchedData.find((a) => a.placeName === value)?.placeId ||
          console.log("No To Place Id"),
      }));
    } else {
      setPlace((prevPlace) => ({
        ...prevPlace,
        fromPlaceId: value,
        fPId:
          fetchedData.find((a) => a.placeName === value)?.placeId ||
          console.log("No From Place Id"),
      }));
    }
  };

  const handleInputChange = (e) => {
    debouncedFetchData(e.target.value);
  };

  return (
    <Box m="20px">
      <Box display="flex" justifyContent="space-between">
        <Header
          title="Add New Route"
          subtitle=""
          style={{ display: "flex", gridColumn: "span 2" }}
        />
      </Box>
      <Formik
        onSubmit={handleSubmit}
        initialValues={initialValues}
        validationSchema={checkoutSchema}
      >
        {({
          values,
          errors,
          touched,
          handleBlur,
          handleChange,
          handleSubmit,
        }) => (
          <form onSubmit={handleSubmit}>
            <Box
              display="grid"
              gap="30px"
              gridTemplateColumns="repeat(4, minmax(0, 1fr))"
              sx={{
                "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
              }}
            >
              <Autocomplete
                fullWidth
                sx={{ gridColumn: "span 2" }}
                name="fromPlaceId"
                options={fetchedData.map((a) => a.placeName)}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Source Name"
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                )}
                onChange={(event, value) => handleSelect(value, "fromPlaceId")}
                onInputChange={(event, newInputValue) =>
                  handleInputChange(event, newInputValue)
                }
              />
              <Autocomplete
                fullWidth
                name="toPlaceId"
                sx={{ gridColumn: "span 2" }}
                options={fetchedData.map((a) => a.placeName)}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Desination Name"
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                )}
                onChange={(event, value) => handleSelect(value, "toPlaceId")}
                onInputChange={(event, newInputValue) =>
                  handleInputChange(event, newInputValue)
                }
              />

              <TextField
                fullWidth
                type="text"
                label="BT Price"
                value={values.btCommission}
                name="btCommission"
                onBlur={handleBlur}
                onChange={handleChange}
                error={!!touched.btCommission && !!errors.btCommission}
                helperText={touched.btCommission && errors.btCommission}
                sx={{ gridColumn: "span 2" }}
              />
              <TextField
                select
                fullWidth
                label="BT Price Type"
                value={values.btCommissionType}
                name="btCommissionType"
                onChange={handleChange}
                error={!!touched.btCommissionType && !!errors.btCommissionType}
                helperText={touched.btCommissionType && errors.btCommissionType}
                sx={{ gridColumn: "span 2" }}
              >
                <MenuItem value="fixed">Fixed</MenuItem>
                <MenuItem value="percent">Percentage</MenuItem>
              </TextField>

              <TextField
                fullWidth
                type="text"
                label="BI Price"
                value={values.biCommission}
                name="biCommission"
                onBlur={handleBlur}
                onChange={handleChange}
                error={!!touched.biCommission && !!errors.biCommission}
                helperText={touched.biCommission && errors.biCommission}
                sx={{ gridColumn: "span 2" }}
              />
              <TextField
                select
                fullWidth
                label="BI Price Type"
                value={values.biCommissionType}
                name="biCommissionType"
                onChange={handleChange}
                error={!!touched.biCommissionType && !!errors.biCommissionType}
                helperText={touched.biCommissionType && errors.biCommissionType}
                sx={{ gridColumn: "span 2" }}
              >
                <MenuItem value="fixed">Fixed</MenuItem>
                <MenuItem value="percent">Percentage</MenuItem>
              </TextField>
              <TextField
                fullWidth
                type="text"
                label="EZ Price"
                value={values.ezCommission}
                onBlur={handleBlur}
                onChange={handleChange}
                name="ezCommission"
                error={!!touched.ezCommission && !!errors.ezCommission}
                helperText={touched.ezCommission && errors.ezCommission}
                sx={{ gridColumn: "span 2" }}
              />
              <TextField
                select
                fullWidth
                label="EZ Price Type"
                value={values.ezCommissionType}
                onChange={handleChange}
                name="ezCommissionType"
                error={!!touched.ezCommissionType && !!errors.ezCommissionType}
                helperText={touched.ezCommissionType && errors.ezCommissionType}
                sx={{ gridColumn: "span 2" }}
              >
                <MenuItem value="fixed">Fixed</MenuItem>
                <MenuItem value="percent">Percentage</MenuItem>
              </TextField>
              <TextField
                id="fromDate"
                label="Start Date"
                type="date"
                inputProps={{ min: today }}
                value={values.fromDate}
                onChange={handleChange}
                InputLabelProps={{
                  shrink: true,
                }}
                fullWidth
                sx={{ gridColumn: "span 2" }}
                error={touched.fromDate && Boolean(errors.fromDate)}
                helperText={touched.fromDate && errors.fromDate}
              />
              <TextField
                id="toDate"
                label="End Date"
                type="date"
                inputProps={{ min: today }}
                value={values.toDate}
                onChange={handleChange}
                InputLabelProps={{
                  shrink: true,
                }}
                fullWidth
                sx={{ gridColumn: "span 2" }}
                error={touched.toDate && Boolean(errors.toDate)}
                helperText={touched.toDate && errors.toDate}
              />
            </Box>
            <Box
              display="flex"
              justifyContent="center"
              mt={
                window.innerHeight > 724
                  ? "40px"
                  : window.innerHeight > 650
                  ? "30px"
                  : "20px"
              }
            >
              <Button
                type="submit"
                variant="contained"
                style={{ ...buttonStyle, marginRight: "2vw" }}
              >
                Add
              </Button>
              <Button
                type="button"
                variant="contained"
                style={{ ...buttonStyle }}
                onClick={handleCancel}
              >
                Cancel
              </Button>
            </Box>
          </form>
        )}
      </Formik>
    </Box>
  );
};
const checkoutSchema = yup.object().shape({
  biCommission: yup
    .number()
    .typeError("BI Price should be a number")
    .required("Required"),
  ezCommission: yup
    .number()
    .typeError("EZ Price should be a number")
    .required("Required"),
  btCommission: yup
    .number()
    .typeError("BT Price should be a number")
    .required("Required"),
  biCommissionType: yup.string().required("Required"),
  ezCommissionType: yup.string().required("Required"),
  btCommissionType: yup.string().required("Required"),
  fromDate: yup
    .date()
    .transform((curr, orig) => (orig === "" ? undefined : curr))
    .required("Start Date is required"),
  toDate: yup
    .date()
    .transform((curr, orig) => (orig === "" ? undefined : curr))
    .required("End Date is required")
    .test(
      "is-greater",
      "End Date should be greater than Start Date",
      function (toDate) {
        const fromDate = this.parent.fromDate;
        return !fromDate || !toDate || new Date(toDate) > new Date(fromDate);
      }
    ),
});

export default AddNewRoute;
