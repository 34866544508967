import { useState, useEffect } from "react";
import { ProSidebar, Menu, MenuItem } from "react-pro-sidebar";
import { Box, IconButton, Typography } from "@mui/material";
import { useNavigate, Navigate } from "react-router-dom";
import "react-pro-sidebar/dist/css/styles.css";
import { faMapMarkerAlt } from "@fortawesome/free-solid-svg-icons";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTags } from "@fortawesome/free-solid-svg-icons";
import { faChair } from "@fortawesome/free-solid-svg-icons";
import { faCalendarAlt } from "@fortawesome/free-solid-svg-icons";
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
import { useSelector, useDispatch } from "react-redux";
import { faCog } from "@fortawesome/free-solid-svg-icons";
import { logout, clearAll } from "../../authslice";
import { active, clearData, clearDatas } from "../../activeslice";
import { faMapSigns } from "@fortawesome/free-solid-svg-icons";
import { checkClick, clearSearch } from "../../searchslice";
import { faHome } from "@fortawesome/free-solid-svg-icons";
import logo from "../../logo.png";
import "../../styles.css";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  hrstyle: {
    borderTop: "1px dashed lightgrey",
    width: "80%",
    textAlign: "center",
  },
  check: {
    display: "flex",
    justifyContent: "center",
  },
}));

const Item = ({ title, to, icon, selected, isCollapsed }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  console.log(window.innerHeight);
  const checkUpdate = useSelector((state) => state.active.updatedData);

  const handleClick = () => {
    if (checkUpdate === true) {
      const confirmation = window.confirm(
        "Do you wish to leave the page without saving the changes? Click OK to leave the page."
      );
      if (!confirmation) {
        return;
      }
    }

    dispatch(checkClick(false));
    dispatch(active(title));
    dispatch(clearDatas());
    dispatch(clearSearch());
    navigate(to);
  };
  return (
    <MenuItem active={selected === title} onClick={handleClick} icon={icon}>
      {!isCollapsed && (
        <Typography
          sx={{
            paddingLeft: "10px",
            color: selected === title ? "black" : "white",
            "&.active": {
              color: "black",
            },
          }}
        >
          {title}
        </Typography>
      )}
    </MenuItem>
  );
};

const Sidebar = () => {
  const classes = useStyles();
  const [logoutClicked, setLogoutClicked] = useState(false);
  const isLoggedIn = useSelector((state) => state.auth.loggedIn);
  const data = useSelector((state) => state.auth.data);
  const selectcheck = useSelector((state) => state.active.activeValue);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [selected, setSelected] = useState(
    selectcheck === "" ? "Dashboard" : selectcheck
  );
  const checkViewStatus = useSelector((state) => state.search.viewStatus);

  useEffect(() => {
    setSelected(selectcheck === "" ? "Dashboard" : selectcheck);
  }, [selectcheck]);
  const handleLogoutClick = () => {
    setLogoutClicked(true);
    setTimeout(() => {
      setLogoutClicked(false);
      handleLogout();
    }, 200);
  };
  const handleLogout = () => {
    if (isLoggedIn) {
      dispatch(logout());
      dispatch(clearData());
      dispatch(clearSearch());
      dispatch(clearAll());
      dispatch(active(""));
      dispatch(clearDatas());
      dispatch(checkClick(false));
    }
    navigate("/");
  };
  if (!isLoggedIn) {
    return <Navigate to="/" />;
  }

  return (
    <Box
      style={{ zIndex: 2, borderRadius: "0px 15px 15px 0" }}
      sx={{
        borderRadius: "0px 15px 15px 0",
        "& .pro-sidebar-inner": {
          backgroundColor: "#002244",
          borderRadius: "0px 15px 15px 0",
        },
        "& .pro-icon-wrapper": {
          backgroundColor: "transparent !important",
        },
        "& .pro-icon-wrapper:hover": {
          backgroundColor: "white",
        },

        "& .pro-inner-item": {
          padding: "1vh 1vw 1vh 0vw !important",
        },
        "& .pro-menu-item.active": {
          backgroundColor: "white",
          color: "black",
        },
        "& .pro-menu-item.active:hover": {
          backgroundColor: "white",
          color: "black",
        },
      }}
    >
      <ProSidebar collapsed={isCollapsed}>
        <Menu iconShape="square">
          <MenuItem
            onClick={() => setIsCollapsed(!isCollapsed)}
            icon={isCollapsed ? <MenuOutlinedIcon /> : undefined}
            style={
              isCollapsed
                ? { color: "white", marginLeft: "25%", marginBottom: "4.1vh" }
                : { color: "white" }
            }
          >
            {!isCollapsed && (
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                ml="15px"
                sx={{
                  marginBottom: "20px",
                }}
              >
                <img src={logo} alt="logo" />
                <IconButton onClick={() => setIsCollapsed(!isCollapsed)}>
                  <MenuOutlinedIcon style={{ color: "white" }} />
                </IconButton>
              </Box>
            )}
          </MenuItem>
          {!isCollapsed && (
            <Box mb="3vh" mt="20px">
              <div className={classes.check}>
                <div className={classes.hrstyle}></div>
              </div>
              {/*<Box display="flex" justifyContent="center" alignItems="center">
                {data.image != null ? (
                  <img
                    alt="profile-user"
                    width="100px"
                    height="100px"
                    src={data.image}
                    style={{ cursor: "pointer", borderRadius: "50%" }}
                  />
                ) : (
                  <img
                    alt="profile-user"
                    width="100px"
                    height="100px"
                    src={image}
                    style={{ cursor: "pointer", borderRadius: "50%" }}
                  />
                )}
                </Box>*/}

              <div className={classes.check}>
                <div className={classes.hrstyle}></div>
              </div>
            </Box>
          )}

          <Box
            sx={{
              "& .pro-menu-item:hover": {
                backgroundColor: "rgba(0, 0, 0, 0.6)",
                color: "white",
              },
              "& .pro-menu-item": {
                marginLeft: "10px",
                borderRadius: "20px 0px 0 20px",
              },
            }}
          >
            {data.dashboardAccess === 1 && (
              <Item
                title="Dashboard"
                to="/dashboard"
                icon={
                  <FontAwesomeIcon
                    icon={faHome}
                    style={{
                      fontSize: 22,
                      fontWeight: "normal",
                      paddingLeft: isCollapsed ? "50%" : "60%",
                      color: selectcheck === "Dashboard" ? "black" : "white",
                    }}
                  />
                }
                selected={selected}
                setSelected={setSelected}
                isCollapsed={isCollapsed}
              />
            )}
            {data.busSettingAccess === 1 && (
              <Item
                title="Bus Settings"
                to="/team"
                icon={
                  <FontAwesomeIcon
                    icon={faCog}
                    style={{
                      fontSize: 22,
                      fontWeight: "normal",
                      paddingLeft: isCollapsed ? "50%" : "60%",
                      color: selectcheck === "Bus Settings" ? "black" : "white",
                    }}
                  />
                }
                selected={selected}
                setSelected={setSelected}
                isCollapsed={isCollapsed}
              />
            )}
            {data.placeMgmtAccess === 1 && (
              <Item
                title="Place Management"
                to="/contacts"
                icon={
                  <FontAwesomeIcon
                    icon={faMapMarkerAlt}
                    style={{
                      fontSize: 22,
                      paddingLeft: isCollapsed ? "50%" : "60%",
                      color:
                        selectcheck === "Place Management" ? "black" : "white",
                    }}
                  />
                }
                selected={selected}
                setSelected={setSelected}
                isCollapsed={isCollapsed}
              />
            )}
            {data.couponMgmtAccess === 1 && (
              <Item
                title="Coupon Management"
                to="/invoices"
                icon={
                  <FontAwesomeIcon
                    icon={faTags}
                    style={{
                      fontSize: 22,
                      paddingLeft: isCollapsed ? "50%" : "60%",
                      color:
                        selectcheck === "Coupon Management" ? "black" : "white",
                    }}
                  />
                }
                selected={selected}
                setSelected={setSelected}
                isCollapsed={isCollapsed}
              />
            )}
            {data.bookingMgmtAccess === 1 && (
              <Item
                title="Booking Management"
                to="/form"
                icon={
                  <FontAwesomeIcon
                    icon={faChair}
                    style={{
                      fontSize: 22,
                      paddingLeft: isCollapsed ? "50%" : "60%",
                      color:
                        selectcheck === "Booking Management"
                          ? "black"
                          : "white",
                    }}
                  />
                }
                selected={selected}
                setSelected={setSelected}
                isCollapsed={isCollapsed}
              />
            )}
            {data.userMgmtAccess === 1 && (
              <Item
                title="User Management"
                to="/calendar"
                icon={
                  <FontAwesomeIcon
                    icon={faCalendarAlt}
                    style={{
                      fontSize: 22,
                      paddingLeft: isCollapsed ? "50%" : "60%",
                      color:
                        selectcheck === "User Management" ? "black" : "white",
                    }}
                  />
                }
                selected={selected}
                setSelected={setSelected}
                isCollapsed={isCollapsed}
              />
            )}
            {data.routeMgmtAccess === 1 && (
              <Item
                title="Application Settings"
                to="/routemgmt"
                icon={
                  <FontAwesomeIcon
                    icon={faMapSigns}
                    style={{
                      fontSize: 22,
                      paddingLeft: isCollapsed ? "50%" : "60%",
                      color:
                        selectcheck === "Application Settings"
                          ? "black"
                          : "white",
                    }}
                  />
                }
                selected={selected}
                setSelected={setSelected}
                isCollapsed={isCollapsed}
              />
            )}

            {window.innerHeight < 600 && (
              <MenuItem
                style={{
                  color: "white",
                  "&:hover": {
                    backgroundColor: "transparent",
                  },
                }}
                className={`logout-button ${logoutClicked ? "clicked" : ""}`}
                onMouseOver={(e) => {
                  e.target.style.backgroundColor = "transparent";
                }}
                onMouseLeave={(e) => {
                  e.target.style.backgroundColor = "transparent";
                }}
                onClick={handleLogoutClick}
              >
                <ExitToAppIcon style={{ color: "white" }} />
                <Typography color={"white"} mt="-1vh">
                  Logout
                </Typography>
              </MenuItem>
            )}
          </Box>
          {window.innerHeight > 599 && (
            <Box
              style={{
                position: "absolute",
                bottom: " 10px",
                left: isCollapsed ? "8%" : "37%",
              }}
              className={`logout-button ${logoutClicked ? "clicked" : ""}`}
              onClick={handleLogoutClick}
            >
              <ExitToAppIcon style={{ color: "white" }} />
              <Typography color={"white"}>Logout</Typography>
            </Box>
          )}
        </Menu>
      </ProSidebar>
    </Box>
  );
};

export default Sidebar;
