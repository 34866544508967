import { Box, Button, useTheme } from "@mui/material";
import { useState, useEffect } from "react";
import InputBase from "@mui/material/InputBase";
import SearchIcon from "@mui/icons-material/Search";
import { useDispatch, useSelector } from "react-redux";
import { checkClick, search } from "../../searchslice";

const Topbar = () => {
  const dispatch = useDispatch();
  const selectcheck = useSelector((state) => state.active.activeValue);
  const searchVal = useSelector((state) => state.search.searchValue);
  const [searchText, setSearchText] = useState(searchVal);
  const handleInput = (e) => {
    setSearchText(e.target.value);
    dispatch(search(e.target.value));
  };
  const handleButtonClick = () => {
    dispatch(checkClick(true));
  };

  useEffect(() => {
    setSearchText(searchVal);
  }, [searchVal]);

  return (
    <Box
      display="flex"
      justifyContent={
        selectcheck === "Booking Management" ||
        selectcheck === "Place Management" ||
        selectcheck === "Application Settings" ||
        selectcheck === "Coupon management"
          ? "space-between"
          : "flex-end"
      }
      p={2}
      marginLeft="10px"
      style={{ borderRadius: "15px 0 15px 15px" }}
      bgcolor="#002244"
      height="9vh"
      minHeight="75px"
    >
      {/* SEARCH BAR */}
      <Box
        display={
          selectcheck === "Booking Management" ||
          selectcheck === "Place Management" ||
          selectcheck === "Application Settings" ||
          selectcheck === "Coupon management"
            ? "flex"
            : "none"
        }
        bgcolor="white"
        borderRadius="3px"
        color="#000"
      >
        <InputBase
          sx={{ ml: 1, flex: 1, color: "#000", width: "200px" }}
          placeholder="Search"
          value={searchText}
          onChange={handleInput}
          inputProps={{ "aria-label": "search" }}
        />
        <SearchIcon sx={{ color: "#000", marginTop: "15px" }} />
      </Box>
      {selectcheck === "Coupon Management" && (
        <Button
          variant="contained"
          style={{ color: "#002244", backgroundColor: "aliceblue" }}
          onClick={handleButtonClick}
        >
          Add New Coupon
        </Button>
      )}
    </Box>
  );
};

export default Topbar;
