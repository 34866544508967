import {
  Box,
  Button,
  TextField,
  FormControlLabel,
  Checkbox,
  MenuItem,
  CircularProgress,
} from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import Header from "../../components/Header";
import axios from "axios";
import { useTheme } from "@mui/material";
import ArrowBackIosOutlinedIcon from "@mui/icons-material/ArrowBackIosOutlined";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { useState } from "react";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  main: {
    width: "95%",
    height: "7vh",
    marginTop: "5px",
    backgroundColor: "white",
    display: "flex",
    justifyContent:"center",
    flexDirection: "column",
    marginLeft: "2.5%",
    paddingLeft: "5%",
    minHeight: "100px",
    minWidth: "630px",
    boxShadow: "1px 1px 1px 1px lightgrey",
    border: "0.2px solid whitesmoke",
  },
  child: {
    width: "20%",
    display: "inline-block",
    color: "grey",
  },
  child2: {
    fontWeight: "700",
    color: "black",
  },
  verticaline: {
    borderLeft:
      "1px dotted lightgrey" /* Adjust the thickness and color as needed */,
    height: "35px" /* Adjust the height as needed */,
    marginRight: "20px",
    display: "inline-block",
  },
  refund: {
    width: "40%",
    height: "50%",
    minWidth: "300px",
    minHeight: "300px",
    display: "flex",
    flexDirection: "column",
    backgroundColor: "aliceblue",
    border: "1px solid seagreen",
    borderRadius: "10px",
    zIndex: 99,
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%,-50%)",
  },
  paydisplay: {
    display: "flex",
    width: "100%",
    height: "20%",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "seagreen",
    fontWeight: "700",
    whiteSpace: "pre",
    borderRadius: "10px 10px 0 0",
  },
  payselect: {
    marginTop: "15%",
    width: "80%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    marginLeft: "10%",
  },
  buttion: {
    display: "flex",
    justifyContent: "center",
    marginTop: "15%",
  },
}));

const Cancelbooking = ({ data, onButtonClick }) => {
  const isNonMobile = useMediaQuery("(min-width:600px)");
  const reduxdata = useSelector((state) => state.auth.data.authorization);
  const classes = useStyles();
  const [cancelData, setCancelData] = useState([]);
  const [showRefundOptions, setShowRefundOptions] = useState(false);
  const [showOverlay, setShowOverlay] = useState(false);
  const [refundOption, setRefundOption] = useState("");
  const [refundData, setRefundData] = useState(null);
  const [loading, setLoading] = useState(false);
  const buttonStyle = { color: "white", backgroundColor: "#002244" };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (cancelData.length === 0) {
      toast.error("Select atleast one booking for cancellation");
    } else {
      setLoading(true);
      const sddata = reduxdata;
      const axiosConfig = {
        headers: {
          Authorization: sddata,
        },
      };
      const datatosend = {
        pnrNumber: data.pnrNumber,
        seatNo: cancelData,
      };
      axios
        .post(
          "https://api.freezebooking.in/v1/admin/getCancellationRefundAmount",
          datatosend,
          axiosConfig
        )
        .then((response) => {
          const data = response.data;
          if (data.meta.status === true && data.meta.code === 200) {
            setShowRefundOptions(true);
            setShowOverlay(true);
            setRefundData(
              data.data.cancelSoap1.DetailsForCancellationResponse
                .cancellationDetails
            );
          } else {
            toast.error(data.meta.message);
          }
        })
        .catch((error) => {
          toast.error(data.meta.message + " Server Error");
          console.error("API error:", error);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };
  const handleCancellation = () => {
    const sddata = reduxdata;
    const axiosConfig = {
      headers: {
        Authorization: sddata,
      },
    };
    setLoading(true);
    const datatosend = {
      pnrNumber: data.pnrNumber,
      seatNo: cancelData,
      refundType: refundOption,
    };
    axios
      .post(
        "https://api.freezebooking.in/v1/admin/bookingCancellation",
        datatosend,
        axiosConfig
      )
      .then((response) => {
        const data = response.data;
        if (data.meta.status === true && data.meta.code === 200) {
          setShowRefundOptions(false);
          setShowOverlay(false);
          setRefundData(null);
          handleCancel();
          toast.success(`Successfully Refunded Amount in ${refundOption}`);
        } else {
          toast.error(data.meta.message + " Server Error");
        }
      })
      .catch((error) => {
        toast.error(data.meta.message);
        console.error("API error:", error);
      })
      .finally(() => {
        setLoading(false);
        setShowRefundOptions(false);
        setShowOverlay(false); // Set loading to false after API call completes (success or failure)
      });
  };

  const handleCancel = () => {
    onButtonClick(false);
  };
  const handleChange = (key) => {
    if (cancelData.includes(key)) {
      setCancelData(cancelData.filter((item) => item !== key));
    } else {
      setCancelData([...cancelData, key]);
    }
  };
  const handleWallet = (e) => {
    setRefundOption(e.target.value);
  };
  const handleOverlayClick = () => {
    setShowRefundOptions(false);
    setShowOverlay(false);
  };

  const handleBoxClick = (e) => {
    e.stopPropagation();
  };

  return (
    <Box m="20px">
      <Header title="Cancel Bookings" />
      <Box style={{ display: "flex", justifyContent: "flex-end" }}>
        <a type="button" variant="contained" onClick={handleCancel}>
          <div
            id="btn"
            style={{
              backgroundColor: "black",
              width: "40px",
              height: "40px",
              display: "inline-flex",
              justifyContent: "center",
              alignItems: "center",
              borderRadius: "50%",
            }}
          >
            <ArrowBackIosOutlinedIcon style={{ color: "white" }} />
          </div>
          <div
            style={{
              color: "black",
              width: "70px",
              height: "40px",
              display: "inline-flex",
              justifyContent: "center",
              fontWeight: 700,
              alignItems: "center",
            }}
          >
            Go Back
          </div>
        </a>
      </Box>
      <h3
        style={{
          marginLeft: "7.3%",
          marginTop: window.innerHeight > 630 ? "-4vh" : "-6vh",
          whiteSpace:"pre"
        }}
      >
        <span style={{}}>Booking Details</span>                                                                        Total Bookings : {data.passengerList.length}
      </h3>
      <Box
        mt="2vh"
        style={{
          overflowY: "auto",
          overflowX: "hidden",
          maxHeight: "400px",
          minHeight: "250px",
          height: "50vh",
        }}
      >
        {data.passengerList.map((item) => (
          <div className={classes.main} key={item.seatNo}>
            
            <div className="classes.main">
              <div className={classes.child}>
                <div>Name:</div>
                <div className={classes.child2}>{item.passengerName}</div>
              </div>
              <div class={classes.verticaline}></div>
              <div className={classes.child}>
                <div>Gender:</div>
                <div className={classes.child2}>{item.passengerGender}</div>
              </div>
              <div class={classes.verticaline}></div>
              <div className={classes.child}>
                <div>Age:</div>
                <div className={classes.child2}>{item.passengerAge}</div>
              </div>
              <div class={classes.verticaline}></div>
              <div className={classes.child}>
                <div>Seat No:</div>
                <div className={classes.child2}>{item.seatNo}</div>
              </div>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={cancelData.includes(item.seatNo)}
                    sx={{
                      color: cancelData.includes(item.seatNo)
                        ? "seagreen"
                        : "black",
                      "&.Mui-checked": {
                        color: "seagreen",
                      },
                      "&:hover": {
                        backgroundColor: "transparent",
                      },
                    }}
                    onChange={() => handleChange(item.seatNo)}
                  />
                }
              />
            </div>
          </div>
        ))}
      </Box>
      <Box display="flex" justifyContent="center" mt="20px">
        <Button
          type="submit"
          onClick={handleSubmit}
          variant="contained"
          style={{ ...buttonStyle, marginRight: "2vw" }}
        >
          Cancel Selected Bookings
        </Button>
      </Box>
      {showRefundOptions && (
        <Box
          className={classes.refund}
          style={{ display: "flex" }}
          onClick={handleBoxClick}
        >
          <Box className={classes.paydisplay}>
            Refund Amount : Rs.{refundData.refundAmount}
          </Box>
          <Box className={classes.payselect}>
            <TextField
              select
              fullWidth
              label="Refund Option"
              value={refundOption}
              onChange={handleWallet}
              sx={{ gridColumn: "span 2" }}
            >
              <MenuItem value="wallet">Freeze Wallet</MenuItem>
              <MenuItem value="bank">Bank Account</MenuItem>
            </TextField>
          </Box>

          <Box className={classes.buttion}>
            <Button onClick={handleCancellation} variant="contained">
              Continue Refund
            </Button>
          </Box>
        </Box>
      )}
      {showOverlay && (
        <div
          onClick={handleOverlayClick}
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(0, 0, 0, 0.8)",
            zIndex: 98,
          }}
        ></div>
      )}
      {loading && (
        <Box
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "rgba(255, 255, 255, 0.8)", // Adjust the background color and opacity
            zIndex: 100,
          }}
        >
          <CircularProgress />
        </Box>
      )}
    </Box>
  );
};

export default Cancelbooking;
