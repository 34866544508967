import { Box } from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { tokens } from "../../theme";
import { useTheme } from "@mui/material";
import { useSelector,useDispatch } from "react-redux";
import { useEffect, useState, useMemo } from "react";
import { debounce } from "lodash";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import axios from "axios";
import Updateplacedetails from "../../Updateplacedetails";
import Header from "../../components/Header";
import "../../styles.css";
import {active} from "../../activeslice";
const Contacts = () => {
  const theme = useTheme();
  const dispatch=useDispatch();
  const colors = tokens(theme.palette.mode);
  const searchval = useSelector((state) => state.search.searchValue);
  const [currentPage, setCurrentPage] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const reduxdata = useSelector((state) => state.auth.data.authorization);
  const memoizedReduxData = useMemo(() => reduxdata, [reduxdata]);
  //total no of items for pagination
  const [pagnation, setPagination] = useState(null);
  const [editUserVisibility, setEditUserVisibility] = useState({
    visibility: false,
    data: null,
  });
  const memoizedSearchVal = useMemo(() => searchval, [searchval]);
  const [fetchedData, setFetchData] = useState([]);
  const getRowId = (row) => row.placeId;
  console.log(window.innerHeight);

  const columns = [
    /*{ field: "placeId", headerName: "ID", flex: 0.5, hide:true },
    { field: "registrarId", headerName: "Place name" },*/
    {
      field: "placeName",
      headerName: "Place Name",
      flex: 1,
      cellClassName: "name-column--cell",
    },
    {
      field: "biCommission",
      headerName: "BI Commission",
      type: "number",
      headerAlign: "left",
      align: "left",
    },
    {
      field: "biCommissionType",
      headerName: "BI Commission Type",
      flex: 1,
    },
    {
      field: "ezCommission",
      headerName: "EZ Commission",
      flex: 1,
    },
    {
      field: "ezCommissionType",
      headerName: "EZ Commission Type",
      flex: 1,
    },
    {
      field: "btCommission",
      headerName: "BT Commission",
      flex: 1,
    },
    {
      field: "btCommissionType",
      headerName: "BT Commission Type",
      flex: 1,
    },
    {
      field: "placeId",
      headerName: "Edit",
      flex: 0.7,
      renderCell: (params) => {
        const handleEdit = (row) => {
          if (row !== null) {
            setEditUserVisibility((prevEditUserVisibility) => ({
              ...prevEditUserVisibility,
              visibility: true,
              data: row,
            }));
          }
          console.log("Edit clicked for row:", row);
          console.log(
            "Updated editUserVisibility:",
            editUserVisibility.visibility
          );
        };

        return (
          <a key={params.row.placeId} onClick={() => handleEdit(params.row)}>
            {" "}
            <EditOutlinedIcon style={{ verticalAlign: "middle" }} />
            Edit
          </a>
        );
      },
    },
  ];
  const debouncedFetchData = useMemo(
    () =>
      debounce((searchString, page) => {
        setIsLoading(true);
        const datatosend = {
          searchString: searchString,
          page: page,
          size: 7,
        };
        console.log(datatosend);
        const axiosConfig = {
          headers: {
            Authorization: memoizedReduxData,
          },
        };
        axios
          .post(
            "https://api.freezebooking.in/v1/admin/getPlaceDetails",
            datatosend,
            axiosConfig
          )
          .then((response) => {
            const data = response.data;
            if (data.meta.status === true && data.meta.code === 200) {
              console.log("Successfully got places");
              setFetchData(data.data.placeDetails);
              setPagination(data.data.totalItems);
            } else {
              console.log("Error Occurred in API");
            }
            setIsLoading(false);
          })
          .catch((error) => {
            console.error("API error:", error);
          });
      }, 300),
    []
  );

  useEffect(() => {
    debouncedFetchData(memoizedSearchVal, currentPage);
  }, [memoizedSearchVal, debouncedFetchData, currentPage]);

  useEffect(()=>{
    dispatch(active("Place Management"));
    },[]);
  const handlePageChange = (params) => {
    setCurrentPage(params);
    debouncedFetchData(memoizedSearchVal, params);
  };
  const handleChildButtonClick = () => {
    setEditUserVisibility({
      visibility: false,
      data: null,
    });
    debouncedFetchData(memoizedSearchVal, currentPage);
  };
  return (
    <>
      {editUserVisibility.visibility ? (
        <Updateplacedetails
          onButtonClick={handleChildButtonClick}
          data={editUserVisibility.data}
        />
      ) : (
        <Box m="2vh 2vh 0 2vh">
          <Header title="Place Management" subtitle="" />
          <Box
            mt={
              window.innerHeight > 740
                ? "3vh"
                : window.innerHeight > 650
                ? "-1vh"
                : "-4vh"
            }
            height={
              window.innerHeight > 724
                ? "515px"
                : window.innerHeight > 650
                ? "450px"
                : "420px"
            }
            sx={{
              "& .MuiDataGrid-root": {
                border: "none",
              },
              "& .MuiDataGrid-cell": {
                borderBottom: "none",
                border: "none !important",
              },
              "& .name-column--cell": {
                color: "black",
                border: "none !important",
              },
              "& .MuiDataGrid-cell:focus-within": {
                outline: "none !important",
                border: "none !important",
              },
              "& .MuiDataGrid-cell:active": {
                outline: "none !important",
                border: "none !important",
              },
              "& .MuiDataGrid-columnHeaders": {
                backgroundColor: "#444444",
                color: "white",
                border: "none !important",
                borderBottom: "none",
              },
              "& .MuiDataGrid-columnHeaders:active": {
                outline: "none !important",
                border: "none !important",
              },
              "& .MuiDataGrid-virtualScroller": {
                backgroundColor: "white",
                border: "1px solid grey",
                overflow: "hidden",
              },
              "& .MuiCheckbox-root": {
                color: `${colors.greenAccent[200]} !important`,
              },
              "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                color: `${colors.grey[100]} !important`,
              },
              "& .MuiDataGrid-footerContainer": {
                borderTop: "none",
                backgroundColor: "#444444",
                color: "white !important",
              },
              "& .MuiDataGrid-footer .MuiDataGridPanel-footer": {
                color: "white !important",
              },
              "& .MuiTablePagination-toolbar": {
                color: "white",
              },
              "& .MuiTablePagination-actions": {
                "& .MuiIconButton-root": {
                  color: "white",
                },
              },
            }}
          >
            <DataGrid
              rows={fetchedData}
              columns={columns}
              pageSize={7}
              getRowClassName={(params) =>
                params.indexRelativeToCurrentPage % 2 === 0
                  ? "Mui-even"
                  : "Mui-odd"
              }
              loading={isLoading}
              disableColumnFilter
              rowCount={pagnation || 0}
              pagination
              disableDensitySelector
              componentsProps={{
                toolbar: {
                  csvOptions: { disableToolbarButton: true },
                  printOptions: { disableToolbarButton: true },
                },
              }}
              getRowId={getRowId}
              components={{ Toolbar: GridToolbar }}
              paginationMode="server"
              onPageChange={handlePageChange}
              getRowHeight={() =>
                window.innerHeight > 724
                  ? 53
                  : window.innerHeight > 650
                  ? 44
                  : 39.5
              }
              localeText={{
                noRowsLabel: "",
              }}
            />
          </Box>
        </Box>
      )}
    </>
  );
};

export default Contacts;
