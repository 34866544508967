import {React} from "react";
import { Box, Button, TextField } from "@mui/material";
import { Formik } from "formik";
import axios from 'axios';
import {useMode} from "./theme";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useNavigate} from 'react-router-dom';
import { login,setData } from './authslice';
import {useDispatch} from 'react-redux';
import Header from "./components/Header";
import { useState,useEffect } from "react";
import { toast } from 'react-toastify';
import fig from './g232.png';
export default function Preapp({email, onButtonClick}){

   
    //useNavigate hook for navigation to the dashboard when data access from api gets succeeed.
    const navigate = useNavigate();
    const isNonMobile = useMediaQuery("(min-width:600px)");
    //to dispatch login() reducer when a persons email is verified
    const dispatch = useDispatch();
    //state variables for taking input and data validation
    const[validOtp,validateOtp]=useState(true);
    const[otp,setOtp]=useState({
        otpValue:'',
        otpValid:true,
        otpInteract:false,
        formvalid:false
    });
    //state variable for taking all the data from api 
    const[userdata,setUserdata]=useState({
        firstName: '',
        lastName: '',
        email: '',
        dashboardAccess: null,
        busSettingAccess: null,
        placeMgmtAccess: null,
        couponMgmtAccess: null,
        bookingMgmtAccess: null,
        userMgmtAccess: null,
        routeMgmtAccess:null,
        cancelAccess:null,
        authorization:'',
    });
    
    //functionality to take input and validate it.
    const handleInputChange=(e)=>{
        const { name, value } = e.target;
        setOtp((prevState) => {
          const updatedState = {
            ...prevState,
            [name]: value,
          };
          const{otpValue,otpValid,otpInteract}=updatedState;
          if((otpValue.length===4 && otpValid===false) || otpValue.length===0)
          {
            if(otpValue.length===0 && otpInteract===true)
            {
                updatedState.formvalid=false;
            }
            else if(otpValue.length===4)
            {
                updatedState.formvalid=true; 
            }
            updatedState.otpValid=true;
          }
          else
          {
            updatedState.otpValid=false;
            updatedState.formvalid=false;
          }
          return updatedState;
        });

    }

   // functionality to close the otp verification window on click of close button
    const handleCancel=()=>{
        onButtonClick(false);
    }
  //functionality to handle api call and directing to the dashboard page when a user gets logged in.
    const handleFormSubmit = (e) => {
        e.preventDefault();
        const otpNumber= otp.otpValue;
          axios
                .post('https://api.freezebooking.in/v1/admin/validateOtp',{email,otpNumber})
                .then(response => {
                    const data = response.data;
                    if(data.meta.status===true && data.meta.code===200)
                    {  
                        setUserdata({
                            ...userdata,
                            firstName: data.data.firstName,
                            lastName: data.data.lastName,
                            email: data.data.email,
                            dashboardAccess: data.data.dashboardAccess,
                            busSettingAccess: data.data.busSettingAccess,
                            placeMgmtAccess: data.data.placeMgmtAccess,
                            couponMgmtAccess: data.data.couponMgmtAccess,
                            bookingMgmtAccess: data.data.bookingMgmtAccess,
                            userMgmtAccess: data.data.userMgmtAccess,
                            routeMgmtAccess:data.data.routeMgmtAccess,
                            cancelAccess:data.data.cancelAccess,
                            authorization:data.data.authorization,
                          });
                        toast.success("Successfully Logged In");
                        dispatch(login());
                    }
                    else{
                        validateOtp(false);
                    }
                })
                .catch((error) => {
                    console.error('API error:', error);
                });
      };

      useEffect(() => {
        if (userdata.email) { 
          dispatch(setData(userdata));
          navigate('/dashboard');
        }
      }, [dispatch, navigate, userdata]);

    return(
      <>
       <div style={{ backgroundImage: `url(${fig})`,backgroundRepeat:"no-repeat",backgroundSize:"cover" }}>
        <Box
          display="flex"
          flexDirection="row"
          alignItems="center"
          justifyContent="center"
          minHeight="100vh"
          marginTop="0vh"
        >
          <div style={{ maxWidth: "40vw", width: "100%", padding: "20px" }}>
            <Header title="OTP" subtitle="Validate Your Account" />
            <Formik
              onSubmit={handleFormSubmit}
            >
              {({
                values,
                errors,
                touched,
                handleBlur,
              }) => (
                <form onSubmit={handleFormSubmit}>
                  <Box
                    display="grid"
                    gap="30px"
                    sx={{
                      "& > div": {
                        gridColumn: isNonMobile ? "span 2" : "span 4",
                      },
                    }}
                  >
                    <TextField
                      fullWidth
                      
                      type="text"
                      label="otp"
                      onBlur={handleBlur}
                      onChange={handleInputChange}
                      value={otp.otpValue}
                      name="otpValue"
                      error={!otp.otpValid}
                      helperText={otp.otpValid ? '' : 'Invalid OTP'}
                      sx={{ gridColumn: "span 2" }}
                    />
                  </Box>
                  {validOtp?'':<span style={{color:"red"}}>Incorrect OTP Entered</span>}
                  <Box display="flex" justifyContent="center" mt="20px">
                    <Button type="submit" variant="contained" disabled={!otp.formvalid} sx={{
                    cursor: "pointer",
                    color:"white",
                      backgroundColor:"#002244",
                  }}>
                      Validate
                    </Button>
                    <Button onClick={handleCancel} type="submit" variant="contained" sx={{
                    cursor: "pointer",
                    color:"white",
                      backgroundColor:"#002244",
                        marginLeft:"2vw",
                  }}>
                      Cancel
                    </Button>
                  </Box>
                </form>
              )}
            </Formik>
          </div>
        </Box>
      </div>
      </>
  );

}