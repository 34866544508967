import { Box, useTheme, Button, TextField, FormControlLabel, Checkbox, Typography, Switch } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { tokens } from "../../theme";
import Header from "../../components/Header";
import axios from 'axios';
import Modal from '@mui/material/Modal';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import { } from '@mui/material';
import { useEffect, useState, useMemo } from "react";
import { debounce } from 'lodash';
import { useSelector,useDispatch} from 'react-redux';
import AddUser from '../../Adduser';
import Updateuser from '../../Updateuser';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import {withStyles } from "@mui/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import {active} from "../../activeslice";
import '../../styles.css'
const CustomTextField = ({ label, value, name }) => {
  return (
    <TextField
      fullWidth
      type="text"
      label={label}
      value={value}
      name={name}
      InputProps={{
        style: {
          color: 'black',  // Set the text color for the disabled state
        },
      }}
      disabled
    />
  );
};
const Team = () => {
  const theme = useTheme();
  const isNonMobile = useMediaQuery("(min-width:600px)");
  const colors = tokens(theme.palette.mode);
  const [isLoading, setIsLoading] = useState(false);
  const reduxdata = useSelector((state) => state.auth.data.authorization);
  console.log(window.innerHeight);
  const dispatch = useDispatch();
  const [userData, setUserData] = useState([]);
  //total no of items for pagination
  const [pagnation,setPagination]=useState(null);
  const [currentPage, setCurrentPage] = useState(0);
  const getRowId = (row) => row.uiUserId;
  const[showAdd,setShowAdd]=useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [editUserVisibility, setEditUserVisibility] = useState({
    visibility:false,
    data:null,
  });
  const convertToBoolean = (value) => !!value;
  const [modalUserData, setModalUserData] = useState([]);
  const [showOverlay,setShowOverlay]=useState(false);
  const CustomSwitch = withStyles({
    switchBase: {
     
      '&$checked': {
        color: "black", 
      },
      '&$checked + $track': {
        backgroundColor: "black", 
      },
    },
    checked: {},
    track: {},
  })(Switch);
  const columns = [
    {
      field: 'fullName',
      headerName: 'Name',
      flex: 1,
      headerClassName: 'column-header',
    },
    {
      field: 'email',
      headerName: 'Email',
      flex: 1,
      headerClassName: 'column-header',
    },
    {
      field: 'toggle',
      headerName: 'Disable',
      flex: 0.3,
      renderCell: (params) => {
        const checked = params.row.status === 1 ? false : true;
        return(
        <FormControlLabel
          control={
            <CustomSwitch
              checked={checked}
              onChange={() => handleToggle(params.row.uiUserId, checked)}
             />
          }
          label=""
        />
      )},   
    },
    {
      field:"placeId",
      headerName: "Edit",
      flex: 0.3,
      renderCell: (params) => {
        const handleEdit = (row) => {
          if (row !== null) {
            setEditUserVisibility((prevEditUserVisibility) => ({
              ...prevEditUserVisibility,
              visibility: true,
              data: row,
            }));}
          console.log("Edit clicked for row:", row);
          console.log("Updated editUserVisibility:", editUserVisibility.visibility);
        };
    
        return (
          <a key={params.row.uiUserId} onClick={() => handleEdit(params.row)}> <EditOutlinedIcon style={{ verticalAlign: 'middle' }}/>Edit</a>
        );
      },
    },
    {
      field: "view",
      headerName: "View",
      flex: 0.4,
      renderCell: (params) => {
        const handleView = (row) => {
          setSelectedUser(row);
          setModalUserData([row]);
          setShowOverlay(true);
        };
        return (
          <a key={params.row.uiUserId} onClick={() => handleView(params.row)}>
            <VisibilityOutlinedIcon style={{ verticalAlign: 'middle',marginRight:'3px' }}/>View
          </a>
        );
      },
    },   
  ];
 
  const fetchUserList = async (page) => {
    setIsLoading(true);
    const sddata = reduxdata;
    const datatosend = {
      "searchString":"",
      "page": page,
      "size": 6,
    };
    const axiosConfig = {
      headers: {
        'Authorization': sddata,
      },
    };
    try {
      const response = await axios.post('https://api.freezebooking.in/v1/admin/getAllUser',datatosend, axiosConfig);
      const data = response.data;
      console.log(data);
      if (data.meta.status === true && data.meta.code === 200) {
        console.log(data.data);
        const modifiedData = data.data.userList.map((item) => ({
          ...item,
          fullName: item.firstName + " " + item.lastName,
          status:item.status,
        }));
        setIsLoading(false);
        setUserData(modifiedData);
        setPagination(data.data.totalItems);
        
      }
    } catch (error) {
      console.error('API error:', error);
    }
  };


  const debouncedFetchUserList = useMemo(
    () =>
      debounce((page) => {
        fetchUserList(page);
      }, 300),
    [] // Ensure that the debounce function is created only once
  );

  useEffect(() => {
    debouncedFetchUserList(currentPage); // Call the debounced function with the current page
  }, [currentPage, debouncedFetchUserList])

  useEffect(()=>{
    dispatch(active("User Management"));
    },[]);

  const handleToggle = (rowId,checked) => {
    const sddata = reduxdata;
    const axiosConfig = {
      headers: {
        'Authorization': sddata,
      },
    };
    const datatosend = {
      "uiUserId":rowId,
    };
  
    if(checked===false)
    { 
      const confirmation = window.confirm(
        "Are you sure you want to disable the user? Click OK to disable the user."
      );
      if (!confirmation) {
        return;
      }
      else
      {
        const updatedData = userData.map((row) =>
        row.uiUserId === rowId ? { ...row, status: row.status === 1 ? 0 : 1 } : row
      );
      setUserData(updatedData);
      axios.post("https://api.freezebooking.in/v1/admin/disableUser",datatosend,axiosConfig)
      .then(response => {
        const data = response.data;
        if(data.meta.status===true && data.meta.code===200)
        {  
          console.log(data.meta.message);
        }
        else{
            console.log("Error occured in API");
        }
    })
    .catch((error) => {
        console.error('API error:', error);
    });
    }
    }
    else{
      const confirmation = window.confirm(
        "Are you sure you want to enable the user? Click OK to enable the user."
      );
      if (!confirmation) {
        return;
      }
      else
      {
        const updatedData = userData.map((row) =>
        row.uiUserId === rowId ? { ...row, status: row.status === 1 ? 0 : 1 } : row
      );
      setUserData(updatedData);
      axios.post("https://api.freezebooking.in/v1/admin/enableUser",datatosend,axiosConfig)
      .then(response => {
        const data = response.data;
        if(data.meta.status===true && data.meta.code===200)
        {  
          console.log(data.meta.message);
        }
        else{
            console.log("Error occured in API");
        }
    })
    .catch((error) => {
        console.error('API error:', error);
    });
    }}
  };
  const handleAddUser=()=>{
    setShowAdd(true);
  }
  const handleChildButtonClick = (message) => {
    setShowAdd(message);
    setEditUserVisibility({visibility:false,data:{}});
    fetchUserList(currentPage);
  };
  const handlePageChange = (params) => {
      setCurrentPage(params);
  };
 
  
  return (<>{editUserVisibility.visibility?(<Updateuser onButtonClick={handleChildButtonClick} data={editUserVisibility.data}/>):
   showAdd?(<AddUser onButtonClick={handleChildButtonClick}/>):(
    <Box m="20px" >
      <Header title="User Management" subtitle="Manage the Users" />
      <Box
        display="flex"
        justifyContent="flex-end"
        alignItems="center"
        mt={window.innerHeight>724?"-4vh":window.innerHeight>650?"2vh":"0vh"}
        mb={window.innerHeight>690?"1vh":"0vh"}
      >
        <Button
          variant="contained"
          sx={{backgroundColor:"#002244",color:"white",marginTop:window.outerHeight>900?"0vh":window.innerHeight>650?"-5vh":"-7vh"}}
          onClick={handleAddUser}
        >
          Add User
        </Button>
      </Box>
      <Box
        mt={window.innerHeight>690?"3vh":"1.5vh"}
         height={window.innerHeight>724?"430px":"360px"}
        
        sx={{
          "& .MuiDataGrid-root": {
            border: "none !important",
            width: "80% !important",
            
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none !important",
            border: "none !important",
            
          },
          "& .MuiDataGrid-cell:focus-within": {
            outline: "none !important",
            border: "none !important",
          },
          "& .MuiDataGrid-cell:active": {
            outline: "none !important",
            border: "none !important",
          },
          "& .name-column--cell": {
            color: colors.greenAccent[300],
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: "#444444",
            color:"white",
            borderBottom: "none !important",
            
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: "white",
            border:"1px solid grey",
            overflow:"hidden",
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none !important",
            color:"white",
            backgroundColor: "#444444",
          },
          "& .MuiCheckbox-root": {
            color: `${colors.greenAccent[200]} !important`,
          },
          "& .MuiTablePagination-toolbar": {
            color: "white",
          },
          "& .MuiTablePagination-actions": {
            "& .MuiIconButton-root": {
              color: "white",
            },
          },
        }}
      >
        <DataGrid 
        style={{ marginLeft: "10%"}} 
        rows={userData} 
        getRowId={getRowId} 
        loading={isLoading}
        columns={columns} 
        pagination
        pageSize={6}
        rowCount={pagnation || 0}
        page={currentPage}
        paginationMode="server"
        onPageChange={handlePageChange}
        disableColumnMenu
        disableColumnSelector
        getRowHeight={() => window.innerHeight>724?53:42}
        localeText={{
          noRowsLabel: isLoading ? '' : '' // Show an empty string if loading, otherwise show 'No Rows'
        }}
        getRowClassName={(params) =>
          params.indexRelativeToCurrentPage % 2 === 0 ? 'Mui-even' : 'Mui-odd'
        }
        />
      </Box>
      {selectedUser && (
        <Modal
          open={selectedUser !== null}
          onClose={() => {
            setSelectedUser(null);
            setModalUserData([]);
            setShowOverlay(false);
          }}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width:"67.5vw",
              display:"flex",
              flexWrap:"nowrap",
              minWidth:"700px",
              minHeight:"450px",
              height:"60vh",
              bgcolor: "background.paper",
              border: "2px solid #00072D",
              boxShadow: 24,
              p:2,
              "& .MuiDataGrid-root": {
                border: "none !important",
                width: "100% !important",
              },
              "& .MuiDataGrid-cell": {
                borderBottom: "none !important",
                border: "none !important",
              },
              "& .MuiDataGrid-cell:focus-within": {
                outline: "none !important",
                border: "none !important",
              },
              "& .MuiDataGrid-cell:active": {
                outline: "none !important",
                border: "none !important",
              },
              "& .name-column--cell": {
                color: colors.greenAccent[300],
              },
              "& .MuiDataGrid-columnHeaders": {
                backgroundColor: "#444444",
                color:"white",
                borderBottom: "none !important",
                
              },
              "& .MuiDataGrid-virtualScroller": {
                backgroundColor: colors.primary[400],
              },
              "& .MuiDataGrid-footerContainer": {
               display:"none",
              },
      
            }}
          >
            {modalUserData.length > 0 && (
              <div style={{display:"flex",justifyContent:"center",zIndex:99 }}>

          <form style={{backgroundColor:"white"}}>
            <Typography variant="h3" style={{textAlign:"center",marginBottom:"4vh",fontWeight:"600"}}>User Info</Typography>
                 <Box
              display="flex"
              style={{verticalAlign:"center",minWidth:"600px",flexWrap:"nowrap",flexDirection:"column",marginLeft:"2.3vh",marginRight:"2.3vh"}}
              gap="30px"
            >
              <Box style={{dispaly:"flex",flexDirection:"row"}}>
              <TextField
                fullWidth
                type="text"
                label="First Name"
                value={selectedUser.firstName}
                sx={{ width:"45%" }}
                name="firstName"
                disabled
              />
              <TextField
                fullWidth
                type="text"
                label="Last Name"
                value={selectedUser.lastName}
                name="lastName"
                sx={{ width:"45%",marginLeft:"10%" }}
                disabled
              />
              </Box>
              <TextField
                fullWidth
                type="text"
                label="Email"
                value={selectedUser.email}
                name="email"
                sx={{ gridColumn: "span 4" }}
                disabled
              />
              <Box
              sx={{
                gridColumn: "1 / span 4",
                mb: "10px",
                border:"1px solid seagreen",
                flexWrap:"nowrap",
                minWidth:"600px",
                padding: "15px",
                display: "grid",
                gridTemplateColumns: "repeat(4, 1fr)",
                justifyContent: "space-evenly",
                alignItems: "center",
                
              }}
            >
              <Typography variant="h4" sx={{ gridColumn: "1 / span 4", mt: "10px",mb:"10px", fontWeight:"bold", color:"seagreen" }}>
            Permissions Granted
            </Typography>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={convertToBoolean(selectedUser.dashboardAccess)}
                    sx={{
                      color: selectedUser.dashboardAccess ? "seagreen" : "black",
                      '&.Mui-checked': {
                        color: "seagreen",
                      },
                      '&:hover': {
                        backgroundColor: "transparent", // optional, removes hover effect
                      },
                    }}
                  />
                }
                disabled
                label="Dashboard Access"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={convertToBoolean(selectedUser.busSettingAccess)}
                    sx={{
                      color: selectedUser.busSettingAccess ? "seagreen" : "black",
                      '&.Mui-checked': {
                        color: "seagreen",
                      },
                      '&:hover': {
                        backgroundColor: "transparent", // optional, removes hover effect
                      },
                    }}
                  />
                }
                disabled
                label="Bus Setting Access"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={convertToBoolean(selectedUser.placeMgmtAccess)}
                    sx={{
                      color: selectedUser.placeMgmtAccess ? "seagreen" : "black",
                      '&.Mui-checked': {
                        color: "seagreen",
                      },
                      '&:hover': {
                        backgroundColor: "transparent", // optional, removes hover effect
                      },
                    }}
                  />
                }
                disabled
                label="Place Management Access"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={convertToBoolean(selectedUser.bookingMgmtAccess)}
                    sx={{
                      color: selectedUser.bookingMgmtAccess ? "seagreen" : "black",
                      '&.Mui-checked': {
                        color: "seagreen",
                      },
                      '&:hover': {
                        backgroundColor: "transparent", // optional, removes hover effect
                      },
                    }}
                  />
                }
                disabled
                label="Coupon Management Access"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={convertToBoolean(selectedUser.couponMgmtAccess)}
                    sx={{
                      color: selectedUser.couponMgmtAccess ? "seagreen" : "black",
                      '&.Mui-checked': {
                        color: "seagreen",
                      },
                      '&:hover': {
                        backgroundColor: "transparent", // optional, removes hover effect
                      },
                    }}
                  />
                }
                disabled
                label="Booking Management Access"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={convertToBoolean(selectedUser.userMgmtAccess)}
                    sx={{
                      color: selectedUser.userMgmtAccess ? "seagreen" : "black",
                      '&.Mui-checked': {
                        color: "seagreen",
                      },
                      '&:hover': {
                        backgroundColor: "transparent", // optional, removes hover effect
                      },
                    }}
                  />
                }
                disabled
                label="User Management Access"
              />
               <FormControlLabel
                control={
                  <Checkbox
                    checked={convertToBoolean(selectedUser.routeMgmtAccess)}
                    sx={{
                      color: selectedUser.routeMgmtAccess ? "seagreen" : "black",
                      '&.Mui-checked': {
                        color: "seagreen",
                      },
                      '&:hover': {
                        backgroundColor: "transparent", // optional, removes hover effect
                      },
                    }}
                  />
                }
                disabled
                label="Route Management Access"
              />
               <FormControlLabel
                control={
                  <Checkbox
                    checked={convertToBoolean(selectedUser.cancelAccess)}
                    sx={{
                      color: selectedUser.cancelAccess ? "seagreen" : "black",
                      '&.Mui-checked': {
                        color: "seagreen",
                      },
                      '&:hover': {
                        backgroundColor: "transparent", // optional, removes hover effect
                      },
                    }}
                  />
                }
                label="Cancel Access"
                disabled
              />
              </Box>
              
              </Box>
                {/*<DataGrid
                  rows={modalUserData}
                  getRowId={getRowId}
                  columns={[
                    { field: 'fullName', headerName: 'Name', width: 150 },
                    { field: 'email', headerName: 'Email', width: 250 },
                    {
                      field: 'dashboardAccess',
                      headerName: 'DashBoard Access',
                      width: 130,
                      renderCell: (params) => (
                        <Checkbox checked={!!params.row.dashboardAccess} disabled />
                      ),
                    },
                    {
                      field: 'busSettingAccess',
                      headerName: 'BusSetting Access',
                      width: 130,
                      renderCell: (params) => (
                        <Checkbox checked={!!params.row.busSettingAccess} disabled/>
                      ),
                    },
                    {
                      field: 'placeMgmtAccess',
                      headerName: 'Place Management Access',
                      width: 180,
                      renderCell: (params) => (
                        <Checkbox checked={!!params.row.placeMgmtAccess} disabled/>
                      ),
                    },
                    {
                      field: 'couponMgmtAccess',
                      headerName: 'Coupon Management Access',
                      width: 180,
                      renderCell: (params) => (
                        <Checkbox checked={!!params.row.couponMgmtAccess} disabled/>
                      ),
                    },
                    {
                      field: 'bookingMgmtAccess',
                      headerName: 'Booking Management Access',
                      width: 180,
                      renderCell: (params) => (
                        <Checkbox checked={!!params.row.bookingMgmtAccess} disabled/>
                      ),
                    },
                    {
                      field: 'userMgmtAccess',
                      headerName: 'User Management Access',
                      width: 170,
                      renderCell: (params) => (
                        <Checkbox checked={!!params.row.userMgmtAccess} disabled />
                      ),
                    }, 
                  ]}
                />
                */}
                 </form>
              </div>
            )}
          </Box>
        </Modal>
      )}
      {showOverlay && (
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(0, 0, 0, 0.8)",
            zIndex: 9,
          }}
        ></div>
      )}
    </Box>)
}
      </>
  );
};

export default Team;
