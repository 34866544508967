import { Box, Button, ButtonGroup, TextField, MenuItem } from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { tokens } from "../../theme";
import { useTheme } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import useMediaQuery from "@mui/material/useMediaQuery";
import { Formik } from "formik";
import * as yup from "yup";
import { useEffect, useState, useMemo } from "react";
import { debounce } from "lodash";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import { toast } from "react-toastify";
import axios from "axios";
import Header from "../../components/Header";
import { checkClick } from "../../searchslice";
import EditCoupon from "./EditCoupon";
import { updateData,active } from "../../activeslice";
import "../../styles.css";

const Contacts = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const searchval = useSelector((state) => state.search.searchValue);
  const showNewCoupon = useSelector((state) => state.search.buttonClick);
  const dispatch = useDispatch();
  const [currentPage, setCurrentPage] = useState(0);
  const checkUpdate = useSelector((state) => state.active.updatedData);
  const [gridKey, setGridKey] = useState(0);
  const isNonMobile = useMediaQuery("(min-width:600px)");
  const [isLoading, setIsLoading] = useState(false);
  const reduxdata = useSelector((state) => state.auth.data.authorization);
  const memoizedReduxData = useMemo(() => reduxdata, [reduxdata]);

  const [imageCheck, setImage] = useState({
    image: null,
    imageUrl: null,
  });

  const today = new Date().toISOString().split("T")[0];
  const initialValues = {
    couponCode: "",
    couponDescription: "",
    couponImage: "",
    couponValue: "",
    couponType: "",
    expiryDate: "",
    units: "",
    termsAndCondition: "",
  };
  const [showActiveButton, setActiveButton] = useState({
    showActive: true,
    showExpired: false,
  });
  //total no of items for pagination
  const [pagnation, setPagination] = useState(null);
  const [editUserVisibility, setEditUserVisibility] = useState({
    visibility: false,
    data: null,
  });
  const memoizedSearchVal = useMemo(() => searchval, [searchval]);
  const [fetchedData, setFetchData] = useState([]);
  const [dataFetched, setDataFetched] = useState(false);
  const getRowId = (row) => row.couponId;

  const columns = [
    /*{ field: "placeId", headerName: "ID", flex: 0.5, hide:true },
    { field: "registrarId", headerName: "Place name" },*/
    {
      field: "couponCode",
      headerName: "Coupon Code",
      flex: 1,
      cellClassName: "name-column--cell",
      renderCell: (params) => (
        <div style={{ fontWeight: "bold" }}>{params.value}</div>
      ),
    },
    {
      field: "couponValue",
      headerName: "Coupon Value",
      type: "number",
      headerAlign: "left",
      align: "left",
    },
    {
      field: "couponImage",
      headerName: "Coupon Image",
      flex: 1,
      renderCell: (params) => {
        if (params.row.couponImage !== null) {
          return (
            <img
              src={params.row.couponImage}
              style={{ width: "auto", height: 40 }}
            />
          );
        } else {
          return "No Image";
        }
      },
    },
    {
      field: "couponType",
      headerName: "Coupon Type",
      flex: 1,
    },
    {
      field: "units",
      headerName: "Total Units",
      flex: 1,
      renderCell: (params) => {
        if (params.row.units !== null) {
          return params.row.units;
        } else {
          return "NULL";
        }
      },
    },
    {
      field: "availableUnits",
      headerName: "Availbale Units",
      flex: 1,
      renderCell: (params) => {
        if (params.row.availableUnits !== null) {
          return params.row.units;
        } else {
          return "NULL";
        }
      },
    },
    {
      field: "expiryDate",
      headerName: "Expiry Date",
      renderCell: (params) => {
        const date = new Date(params.row.expiryDate);
        return <div>{date.toLocaleDateString()}</div>;
      },
      // handle other fields if needed
    },
    {
      field: "couponId",
      headerName: "Edit",
      flex: 0.7,
      renderCell: (params) => {
        const handleEdit = (row) => {
          if (row !== null) {
            setEditUserVisibility((prevEditUserVisibility) => ({
              ...prevEditUserVisibility,
              visibility: true,
              data: row,
            }));
          }
        };
        return (
          <a key={params.row.couponId} onClick={() => handleEdit(params.row)}>
            <EditOutlinedIcon style={{ verticalAlign: "middle" }} />
            Edit
          </a>
        );
      },
    },
  ];

  const debouncedFetchData = useMemo(
    () =>
      debounce((searchString, showActiveButton, page) => {
        const datatosend = {
          searchString: searchString,
          page: page,
          size: 5,
        };
        console.log(datatosend);
        const axiosConfig = {
          headers: {
            Authorization: memoizedReduxData,
          },
        };
        if (showActiveButton.showActive === true) {
          axios
            .post(
              "https://api.freezebooking.in/v1/admin/getActiveCoupon",
              datatosend,
              axiosConfig
            )
            .then((response) => {
              const data = response.data;
              if (data.meta.status === true && data.meta.code === 200) {
                setFetchData(data.data.couponList);
                setPagination(data.data.totalItems);
                setDataFetched(true);
              } else {
                console.log(data.meta.message);
              }
            })
            .catch((error) => {
              console.error("API error:", error);
            })
            .finally(() => {
              setIsLoading(false);
            });
        } else if (showActiveButton.showExpired === true) {
          axios
            .post(
              "https://api.freezebooking.in/v1/admin/getExpiredCoupon",
              datatosend,
              axiosConfig
            )
            .then((response) => {
              const data = response.data;
              if (data.meta.status === true && data.meta.code === 200) {
                setFetchData(data.data.couponList);
                setPagination(data.data.totalItems);
                setDataFetched(true);
              } else {
                console.log(data.meta.message);
              }
            })
            .catch((error) => {
              console.error("API error:", error);
            })
            .finally(() => {
              setIsLoading(false);
            });
        }
      }, 300),
    []
  );

  useEffect(() => {
    setIsLoading(true);
    setDataFetched(false);
    debouncedFetchData(memoizedSearchVal, showActiveButton, currentPage);
  }, [memoizedSearchVal, showActiveButton, debouncedFetchData, currentPage]);

  useEffect(()=>{
    dispatch(active("Coupon Management"));
    },[]);

  const handlePageChange = (params) => {
    setCurrentPage(params);
    debouncedFetchData(memoizedSearchVal, showActiveButton, params);
  };
  const buttonStyle = { color: "white", backgroundColor: "#002244" };

  const handleClick = (e) => {
    if (e.target.name === "active") {
      setActiveButton({
        showActive: true,
        showExpired: false,
      });
      dispatch(checkClick(false));
      setCurrentPage(0);
    } else if (e.target.name === "expired") {
      setActiveButton({
        showActive: false,
        showExpired: true,
      });
      dispatch(checkClick(false));
      setPagination(null);
      setCurrentPage(0);
      setGridKey((prev) => prev + 1);
    }
  };
  const handleCancel = () => {
    if (checkUpdate === true) {
      const confirmation = window.confirm(
        "Do you wish to leave the page without saving the changes? Click OK to leave the page."
      );
      if (!confirmation) {
        return;
      } else {
        dispatch(updateData(false));
        dispatch(checkClick(false));
        setImage({
          image: null,
          imageUrl: null,
        });
        debouncedFetchData(memoizedSearchVal, showActiveButton, currentPage);
      }
    } else {
      dispatch(updateData(false));
      dispatch(checkClick(false));
      setImage({
        image: null,
        imageUrl: null,
      });
      debouncedFetchData(memoizedSearchVal, showActiveButton, currentPage);
    }
  };
  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setImage({ image: file });
    console.log(file);
    const sddata = reduxdata;
    const formData = new FormData();
    formData.append("file", file);
    const axiosConfig = {
      headers: {
        Authorization: sddata,
        "Content-Type": "multipart/form-data",
      },
    };
    axios
      .post(
        "https://api.freezebooking.in/v1/admin/uploadFile",
        formData,
        axiosConfig
      )
      .then((response) => {
        const data = response.data;
        if (data.meta.status === true && data.meta.code === 200) {
          toast.success("Successfully Uploaded Image");
          toast.success("Add Coupon Details");
          setImage((prevState) => ({
            ...prevState,
            imageUrl: data.data.fileNameUrl,
          }));
          dispatch(updateData(true));
        } else {
          console.log(data.meta.message);
        }
      })
      .catch((error) => {
        console.error("API error:", error);
      });
  };

  const handleSubmit = (values, { resetForm }) => {
    const sddata = reduxdata;
    const axiosConfig = {
      headers: {
        Authorization: sddata,
      },
    };
    const datatosend = {
      ...values,
      couponImage: imageCheck.imageUrl,
    };
    console.log(datatosend);
    Object.keys(datatosend).forEach((key) => {
      if (datatosend[key] === "") {
        datatosend[key] = null;
      }
    });
    console.log(datatosend);
    axios
      .post(
        "https://api.freezebooking.in/v1/admin/addCoupon",
        datatosend,
        axiosConfig
      )
      .then((response) => {
        const data = response.data;
        if (data.meta.status === true && data.meta.code === 200) {
          resetForm();
          toast.success("Successfully Added New Coupon");
          dispatch(updateData(false));
          setImage({
            image: null,
            imageUrl: null,
          });
        } else {
          console.log(data.meta.message);
        }
      })
      .catch((error) => {
        console.error("API error:", error);
      });
  };
  const handleChildButtonClick = () => {
    setEditUserVisibility({ visibility: false, data: {} });
    debouncedFetchData(memoizedSearchVal, showActiveButton, currentPage);
  };
  return (
    <>
      <Box m="2vh">
        <ButtonGroup
          style={{
            width: "100%",
            color: "whitesmoke",
            display: showNewCoupon
              ? "none"
              : "" || editUserVisibility.visibility
              ? "none"
              : "",
          }}
        >
          <Box
            border={1}
            borderColor="white"
            borderRadius={5}
            style={{ flex: 1 }}
          >
            <Button
              name="active"
              onClick={handleClick}
              style={{
                width: "100%",
                borderColor: "black",
                backgroundColor: showActiveButton.showActive
                  ? "whitesmoke"
                  : "#002244",
                color: showActiveButton.showActive ? "#002244" : "white",
              }}
            >
              Active Coupons
            </Button>
          </Box>
          <Box
            border={1}
            borderColor="white"
            borderRadius={5}
            style={{ flex: 1 }}
          >
            <Button
              name="expired"
              onClick={handleClick}
              style={{
                width: "100%",
                borderColor: "black",
                backgroundColor: showActiveButton.showExpired
                  ? "whitesmoke"
                  : "#002244",
                color: showActiveButton.showExpired ? "#002244" : "white",
              }}
            >
              Expired Coupons
            </Button>
          </Box>
        </ButtonGroup>
      </Box>
      {editUserVisibility.visibility ? (
        <EditCoupon
          onButtonClick={handleChildButtonClick}
          data={editUserVisibility.data}
        />
      ) : showNewCoupon ? (
        <Box m="20px">
          <Box display="flex" justifyContent="space-between">
            <Header
              title="Add New Coupon"
              subtitle=""
              style={{ display: "flex", gridColumn: "span 2" }}
            />
            <Box
              sx={{
                gridColumn: "span 2",
                display: "inline-block",
                alignItems: "center",
                fontWeight: "bold",
                margin: "1vh 3vh 0 0",
              }}
            >
              <label
                htmlFor="contained-button-file"
                style={{ marginRight: "10px" }}
              >
                Coupon Image
              </label>
              <input
                accept="image/*"
                id="contained-button-file"
                type="file"
                style={{ display: "none" }}
                onChange={handleFileChange}
              />
              <label htmlFor="contained-button-file">
                <Button variant="contained" component="span">
                  Upload
                </Button>
                <Box
                  style={{
                    backgroundColor: "grey",
                    display: "flex",
                    justifyContent: "center",
                  }}
                  mt="1vh"
                  mb="1vh"
                >
                  {imageCheck.image && <p>{imageCheck.image.name}</p>}
                </Box>
              </label>
            </Box>
          </Box>
          <Formik
            onSubmit={handleSubmit}
            initialValues={initialValues}
            validationSchema={checkoutSchema}
          >
            {({
              values,
              errors,
              touched,
              handleBlur,
              handleChange,
              handleSubmit,
            }) => (
              <form onSubmit={handleSubmit}>
                <Box
                  display="grid"
                  gap="30px"
                  gridTemplateColumns="repeat(4, minmax(0, 1fr))"
                  sx={{
                    "& > div": {
                      gridColumn: isNonMobile ? undefined : "span 4",
                    },
                  }}
                >
                  <TextField
                    fullWidth
                    type="text"
                    label="Coupon Code"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.couponCode}
                    name="couponCode"
                    error={!!touched.couponCode && !!errors.couponCode}
                    helperText={touched.couponCode && errors.couponCode}
                    sx={{ gridColumn: "span 2" }}
                  />
                  <TextField
                    fullWidth
                    type="text"
                    label="Coupon Description"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.couponDescription}
                    name="couponDescription"
                    error={
                      !!touched.couponDescription && !!errors.couponDescription
                    }
                    helperText={
                      touched.couponDescription && errors.couponDescription
                    }
                    sx={{ gridColumn: "span 2" }}
                  />
                  <TextField
                    fullWidth
                    type="text"
                    label="Coupon Value"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.couponValue}
                    name="couponValue"
                    error={!!touched.couponValue && !!errors.couponValue}
                    helperText={touched.couponValue && errors.couponValue}
                    sx={{ gridColumn: "span 2" }}
                  />
                  <TextField
                    select
                    fullWidth
                    label="Coupon Type"
                    value={values.couponType}
                    onChange={handleChange}
                    name="couponType"
                    error={!!touched.couponType && !!errors.couponType}
                    helperText={touched.couponType && errors.couponType}
                    sx={{ gridColumn: "span 2" }}
                  >
                    <MenuItem value="fixed">Fixed</MenuItem>
                    <MenuItem value="percentage">Percentage</MenuItem>
                  </TextField>
                  <TextField
                    id="expiryDate"
                    label="Expiry Date"
                    type="date"
                    inputProps={{ min: today }}
                    value={values.expiryDate}
                    onChange={handleChange}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    fullWidth
                    sx={{ gridColumn: "span 2" }}
                    error={touched.expiryDate && Boolean(errors.expiryDate)}
                    helperText={touched.expiryDate && errors.expiryDate}
                  />
                  <TextField
                    fullWidth
                    type="number"
                    inputProps={{ min: 1 }}
                    label="Total Units"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.units}
                    name="units"
                    error={!!touched.units && !!errors.units}
                    helperText={touched.units && errors.units}
                    sx={{ gridColumn: "span 2" }}
                  />
                  <TextField
                    fullWidth
                    multiline
                    rows={4} // Adjust the number of rows to fit your requirements
                    type="text"
                    label="Terms and Conditions"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.termsAndCondition}
                    name="termsAndCondition"
                    error={
                      !!touched.termsAndCondition && !!errors.termsAndCondition
                    }
                    helperText={
                      touched.termsAndCondition && errors.termsAndCondition
                    }
                    sx={{ gridColumn: "span 4" }}
                  />
                </Box>
                <Box display="flex" justifyContent="center" mt="20px">
                  <Button
                    type="submit"
                    variant="contained"
                    style={{ ...buttonStyle, marginRight: "2vw" }}
                  >
                    Add
                  </Button>
                  <Button
                    type="button"
                    variant="contained"
                    style={{ ...buttonStyle }}
                    onClick={handleCancel}
                  >
                    Cancel
                  </Button>
                </Box>
              </form>
            )}
          </Formik>
        </Box>
      ) : (
        <Box m="2vh">
          <Box
            mt={
              window.innerHeight > 740
                ? "5vh"
                : window.innerHeight > 650
                ? "3vh"
                : "1vh"
            }
            height={
              window.innerHeight > 724
                ? "505px"
                : window.innerHeight > 650
                ? "442px"
                : "410px"
            }
            sx={{
              "& .MuiDataGrid-root": {
                border: "none",
              },
              "& .MuiDataGrid-cell": {
                borderBottom: "none",
                border: "none !important",
              },
              "& .name-column--cell": {
                color: "black",
                border: "none !important",
              },
              "& .MuiDataGrid-cell:focus-within": {
                outline: "none !important",
                border: "none !important",
              },
              "& .MuiDataGrid-cell:active": {
                outline: "none !important",
                border: "none !important",
              },
              "& .MuiDataGrid-columnHeaders": {
                backgroundColor: "#444444",
                color: "white",
                border: "none !important",
                borderBottom: "none",
              },
              "& .MuiDataGrid-columnHeaders:active": {
                outline: "none !important",
                border: "none !important",
              },
              "& .MuiDataGrid-virtualScroller": {
                backgroundColor: "white",
                border: "1px solid grey",
                overflow: "hidden",
              },
              "& .MuiCheckbox-root": {
                color: `${colors.greenAccent[200]} !important`,
              },
              "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                color: `${colors.grey[100]} !important`,
              },
              "& .MuiDataGrid-footerContainer": {
                borderTop: "none",
                backgroundColor: "#444444",
                color: "white !important",
              },
              "& .MuiDataGrid-footer .MuiDataGridPanel-footer": {
                color: "white !important",
              },
              "& .MuiTablePagination-toolbar": {
                color: "white",
              },
              "& .MuiTablePagination-actions": {
                "& .MuiIconButton-root": {
                  color: "white",
                },
              },
            }}
          >
            <DataGrid
              key={gridKey}
              rows={fetchedData}
              columns={columns}
              loading={isLoading}
              pageSize={5}
              getRowClassName={(params) =>
                params.indexRelativeToCurrentPage % 2 === 0
                  ? "Mui-even"
                  : "Mui-odd"
              }
              disableColumnFilter
              rowCount={pagnation || 0}
              pagination
              getRowHeight={() =>
                window.innerHeight > 724
                  ? 72
                  : window.innerHeight > 650
                  ? 60
                  : 54
              }
              getRowId={getRowId}
              localeText={{
                noRowsLabel: isLoading
                  ? ""
                  : showActiveButton.showActive
                  ? dataFetched && fetchedData.length === 0
                    ? "No Active Coupons"
                    : ""
                  : dataFetched && fetchedData.length === 0
                  ? "No Expired Coupons"
                  : "",
              }}
              disableDensitySelector
              componentsProps={{
                toolbar: {
                  csvOptions: { disableToolbarButton: true },
                  printOptions: { disableToolbarButton: true },
                },
              }}
              components={{ Toolbar: GridToolbar }}
              paginationMode="server"
              onPageChange={handlePageChange}
            />
          </Box>
        </Box>
      )}
    </>
  );
};
const checkoutSchema = yup.object().shape({
  couponCode: yup
    .string()
    .matches(
      /^[\w\s]+$/,
      "Coupon Code should contain only alphanumeric characters and spaces"
    )
    .required("Required"),
  couponDescription: yup
    .string()
    .matches(
      /^[a-zA-Z0-9]*$/,
      "Coupon Description should contain only alphanumeric characters"
    ),
  couponValue: yup
    .number()
    .typeError("Coupon Value should be a number")
    .required("Required"),
  units: yup.number().typeError("Total Units should be a number").nullable(),
  couponType: yup.string().required("Required"),
  expiryDate: yup
    .date()
    .transform((curr, orig) => (orig === "" ? undefined : curr))
    .required("Expiry Date is required"),
  termsAndCondition: yup
    .string()
    .matches(
      /^[a-zA-Z0-9]*$/,
      "Terms and Conditions should contain only alphanumeric characters"
    )
    .nullable(),
});
export default Contacts;
