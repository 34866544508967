import { Box, Button, TextField, FormControlLabel, Checkbox, Typography } from "@mui/material";
import { Formik } from "formik";
import * as yup from "yup";
import useMediaQuery from "@mui/material/useMediaQuery";
import Header from "./components/Header";
import axios from "axios";
import { toast } from 'react-toastify';
import { useSelector} from 'react-redux';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(theme => ({
    main:{
        color:"seagreen",
       
          "&.Mui-checked": {
            color: "seagreen",
          },
        
    }
    
    }));


const Updateuser = ({onButtonClick,data}) => {
  const isNonMobile = useMediaQuery("(min-width:600px)");
  const reduxdata = useSelector((state) => state.auth.data.authorization);
  console.log(data.data);
  const classes=useStyles();
  const initialValues = {
    ...data,
  };
  const buttonStyle={color:"white",
    backgroundColor:"#002244"}

    const convertToBoolean = (value) => !!value;

  const handleSubmit=(values,{resetForm})=>{
    console.log(values);
    
    const sddata=reduxdata;
    const axiosConfig = {
        headers: {
          'Authorization': sddata,
        },
      };
    const datatosend={
       uiUserId:data.uiUserId,
       ...values,
    };
    if(JSON.stringify(datatosend) === JSON.stringify(initialValues))
    {
        toast.success("Already updated");
        handleCancel();
    }
    else{
        axios
                .post('https://api.freezebooking.in/v1/admin/updateUser',datatosend,axiosConfig)
                .then(response => {
                    const data = response.data;
                    if(data.meta.status===true && data.meta.code===200)
                    {   
                        resetForm();
                        toast.success("Successfully Updated Data");
                        handleCancel();
                    }
                    else{
                        console.log(data.meta.message);
                    }
                })
                .catch((error) => {
                    console.error('API error:', error);
                });
  }
}
  const handleCancel=()=>{
    onButtonClick(false);
}
  return (
    <Box m="20px">
      <Header title="Update User" subtitle="Update User Profile" />

      <Formik
        onSubmit={handleSubmit}
        initialValues={initialValues}
        validationSchema={checkoutSchema}
      >
        {({
          values,
          errors,
          touched,
          handleBlur,
          handleChange,
          handleSubmit,
          setFieldValue,
        }) => (
          <form onSubmit={handleSubmit}>
            <Box
              display="grid"
              gap="30px"
              gridTemplateColumns="repeat(4, minmax(0, 1fr))"
              sx={{
                "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
              }}
            >
              <TextField
                fullWidth
                
                type="text"
                label="First Name"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.firstName}
                name="firstName"
                error={!!touched.firstName && !!errors.firstName}
                helperText={touched.firstName && errors.firstName}
                sx={{ gridColumn: "span 2" }}
              />
              <TextField
                fullWidth
                
                type="text"
                label="Last Name"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.lastName}
                name="lastName"
                error={!!touched.lastName && !!errors.lastName}
                helperText={touched.lastName && errors.lastName}
                sx={{ gridColumn: "span 2" }}
              />
              <TextField
                fullWidth
                
                type="text"
                label="Email"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.email}
                name="email"
                error={!!touched.email && !!errors.email}
                helperText={touched.email && errors.email}
                sx={{ gridColumn: "span 4" }}
              />
              <Box
              sx={{
                gridColumn: "1 / span 4",
                mb: "10px",
                border:"1px solid seagreen",
                padding: "15px",
                display: "grid",
                gridTemplateColumns: "repeat(4, 1fr)",
                justifyContent: "space-evenly",
                alignItems: "center",
                
              }}
            >
              <Typography variant="h4" sx={{ gridColumn: "1 / span 4", mt: "10px",mb:"10px", fontWeight:"bold", color:"seagreen" }}>
              Grant Permissions
            </Typography>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={convertToBoolean(values.dashboardAccess)}
                    sx={{
                      color: values.dashboardAccess ? "seagreen" : "black",
                      '&.Mui-checked': {
                        color: "seagreen",
                      },
                      '&:hover': {
                        backgroundColor: "transparent", // optional, removes hover effect
                      },
                    }}
                    className={classes.main}
                     onChange={(e) =>
                      setFieldValue("dashboardAccess", e.target.checked ? 1 : 0)
                    }
                  />
                }
                label="Dashboard Access"
              />
              <FormControlLabel
                control={
                  <Checkbox
                  className={classes.main}
                    checked={convertToBoolean(values.busSettingAccess)}
                    sx={{
                      color: values.busSettingAccess ? "seagreen" : "black",
                      '&.Mui-checked': {
                        color: "seagreen",
                      },
                      '&:hover': {
                        backgroundColor: "transparent", // optional, removes hover effect
                      },
                    }}
                    onChange={(e) =>
                      setFieldValue("busSettingAccess", e.target.checked ? 1 : 0)
                    }
                  />
                }
                label="Bus Setting Access"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={convertToBoolean(values.placeMgmtAccess)}
                    sx={{
                      color: values.placeMgmtAccess ? "seagreen" : "black",
                      '&.Mui-checked': {
                        color: "seagreen",
                      },
                      '&:hover': {
                        backgroundColor: "transparent", // optional, removes hover effect
                      },
                    }}
                    className={classes.main}
                    onChange={(e) =>
                      setFieldValue("placeMgmtAccess", e.target.checked ? 1 : 0)
                    }
                  />
                }
                label="Place Management Access"
              />
              <FormControlLabel
                control={
                  <Checkbox
                  className={classes.main}
                    checked={convertToBoolean(values.bookingMgmtAccess)}
                    sx={{
                      color: values.bookingMgmtAccess ? "seagreen" : "black",
                      '&.Mui-checked': {
                        color: "seagreen",
                      },
                      '&:hover': {
                        backgroundColor: "transparent", // optional, removes hover effect
                      },
                    }}
                    onChange={(e) =>
                      setFieldValue("bookingMgmtAccess", e.target.checked ? 1 : 0)
                    }
                  />
                }
                label="Coupon Management Access"
              />
              <FormControlLabel
                control={
                  <Checkbox
                  className={classes.main}
                    checked={convertToBoolean(values.couponMgmtAccess)}
                    sx={{
                      color: values.couponMgmtAccess ? "seagreen" : "black",
                      '&.Mui-checked': {
                        color: "seagreen",
                      },
                      '&:hover': {
                        backgroundColor: "transparent", // optional, removes hover effect
                      },
                    }}
                    onChange={(e) =>
                      setFieldValue("couponMgmtAccess", e.target.checked ? 1 : 0)
                    }
                  />
                }
                label="Booking Management Access"
              />
              <FormControlLabel
                control={
                  <Checkbox
                  className={classes.main}
                    checked={convertToBoolean(values.userMgmtAccess)}
                    sx={{
                      color: values.userMgmtAccess ? "seagreen" : "black",
                      '&.Mui-checked': {
                        color: "seagreen",
                      },
                      '&:hover': {
                        backgroundColor: "transparent", // optional, removes hover effect
                      },
                    }}
                    onChange={(e) =>
                      setFieldValue("userMgmtAccess", e.target.checked ? 1 : 0)
                    }
                  />
                }
                label="User Management Access"
              />
               <FormControlLabel
                control={
                  <Checkbox
                  className={classes.main}
                    checked={convertToBoolean(values.routeMgmtAccess)}
                    sx={{
                      color: values.routeMgmtAccess ? "seagreen" : "black",
                      '&.Mui-checked': {
                        color: "seagreen",
                      },
                      '&:hover': {
                        backgroundColor: "transparent", // optional, removes hover effect
                      },
                    }}
                    onChange={(e) =>
                      setFieldValue("routeMgmtAccess", e.target.checked ? 1 : 0)
                    }
                  />
                }
                label="Route Management Access"
              />
               <FormControlLabel
                control={
                  <Checkbox
                  className={classes.main}
                    checked={convertToBoolean(values.cancelAccess)}
                    sx={{
                      color: values.cancelAccess ? "seagreen" : "black",
                      '&.Mui-checked': {
                        color: "seagreen",
                      },
                      '&:hover': {
                        backgroundColor: "transparent", // optional, removes hover effect
                      },
                    }}
                    onChange={(e) =>
                      setFieldValue("cancelAccess", e.target.checked ? 1 : 0)
                    }
                  />
                }
                label="Cancel Access"
              />
              </Box>
            </Box>
            <Box display="flex" justifyContent="center" mt="20px">
              <Button type="submit"  variant="contained" style={{...buttonStyle,marginRight:"2vw"}}>
                Update
              </Button>
              <Button type="button"  variant="contained" style={{...buttonStyle}} onClick={handleCancel}>
                Cancel
              </Button>
            </Box>
          </form>
        )}
      </Formik>
    </Box>
  );
};

const checkoutSchema = yup.object().shape({
    firstName: yup.string().matches(/^[A-Za-z]+$/, 'First name should contain only alphabets').required("required"),
    lastName: yup.string().matches(/^[A-Za-z]+$/, 'Last name should contain only alphabets').required("required"),
    email: yup.string().email("invalid email").required("required"),
  });

export default Updateuser;
